import { Container } from "@mui/material";

export function AnalyticsPage() {
    return  <Container maxWidth={false} disableGutters sx={{ height: 'calc(100vh - 64px)' }}>
        <iframe
            src="https://analytics.markets-platform.com/d/candlestick/candlestick?orgId=1"
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
            }}
            title="External Content"
        />
    </Container>
}