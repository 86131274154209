export interface ApiCandle {
    time?: number;
    open?: number;
    high?: number;
    low?: number;
    close?: number;
    volume?: number;
    openTradeID?: number;
    closeTradeID?: number;

}

export interface ApiRequest {
    abortController?: AbortController;
    body?: any;
    headers?: [string, string][]
    uri?: string;
    method?: string;
    params?: { [key: string]: any };
}

// API Candle Interval
export enum ApiCandleInterval {
    M1 = '1m',
    M5 = '5m',
    M15 = '15m',
    H1 = '1h',
    H4 = '4h',
}


// API Candle Request representation
export interface ApiCandleRequest {
    exchange: string;
    symbol: string;
    interval?: ApiCandleInterval;
    limit?: number;
    startTime?: number;
    endTime?: number;
}


/**
 * API Service
 */
export class ApiService {

    /** The host of the API */
    private readonly host: string;

    constructor(
        host = 'https://back.markets-platform.com',
    ) {
        this.host = host;
    }

    /**
     * Query the API
     * @param {ApiRequest} request - The request object
     * @param {number} timeout - The request timeout in milliseconds (default 4000)
     * */
    async query(request: ApiRequest, timeout: number = 4e3) {
        let url = this.host + request.uri;

        // Add query params
        if (request.params) {
            url += `?${(Object.keys(request.params).map(key => {
                if (!request.params) {
                    return '';
                }

                return `${encodeURI(key)}=${encodeURI(request.params[key] ? request.params[key] : '')}`;
            }).join('&'))}`;
        }

        const controller = new AbortController();
        request.abortController = controller;

        // Set a timeout for the request
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        return await fetch(url, {
            method: request.method ? request.method : 'GET',
            signal: controller.signal,
            headers: request.headers ? request.headers : [['Content-Type', 'application/json']],
            body: ['array', 'object'].includes(typeof request.body) ? JSON.stringify(request.body) : null
        })
    }

    /**
     * Get candles
     * @param {ApiCandleRequest} request - The candle request
     * @see https://back.markets-platform.com/api/index.html#/data/get_candles
     */
    async getCandles(request: ApiCandleRequest) {
        const response = await this.query({
            uri: '/instrument/candles',
            method: 'GET',
            params: request
        });
        if (response.ok) {
            return await response.json() as ApiCandle[];
        } else {
            throw new Error('Failed to fetch candles');
        }
    }


    /**
     * Register a new user
     */
    async register(email: string, password: string) {
        const response = await this.query({
            uri: '/auth/register',
            method: 'POST',
            body: {email, password}
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to register');
        }
    }

    /**
     * Confirm email
     */
    async confirmEmail(userHash: string) {
        const response = await this.query({
            uri: '/auth/confirm',
            method: 'GET',
            params: {h: userHash}
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to confirm email');
        }
    }

    /**
     * Login a user
     */
    async login(email: string, password: string) {
        const response = await this.query({
            uri: '/auth/login',
            method: 'POST',
            body: {email, password}
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to login');
        }
    }

    /**
     * Delete a user account
     */
    async deleteUser(email: string) {
        const response = await this.query({
            uri: '/auth/delete',
            method: 'DELETE',
            body: {email}
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to delete user');
        }
    }
}
