import React from 'react';
import { useTranslation } from "react-i18next";

import { Logo } from "../../Common/Components/Logo/Logo";
import { NavButton } from "../../Common/Components/NavButton/NavButton";
import { loginPath, pathAnalytics, pathMarketRePlay, pathTrading } from "../../Routes/Routes";
import TradingIcon from "../../Common/Components/Icons/ButtonIcons/TradingIcon.svg";
import MarketRePlayIcon from "../../Common/Components/Icons/ButtonIcons/MarketRePlayIcon.svg";
import AnalyticsIconImg from '../../Common/Components/Icons/ButtonIcons/AnalyticsIcon.svg'
import userIcon from '../../user_icon.svg';

import "./NavigationMenu.scss";
import { useNavigate } from 'react-router-dom';

/* Боковое меню навигации.*/
export const NavigationMenu: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="navigation-menu-container">
            <Logo />
            <NavButton
                label={t('Components:Components.NavigationMenu.Trading')}
                icon={<img src={TradingIcon} alt="Перейти в трейдинг" />}
                pathToGo={pathTrading}
            />
            {/* <NavButton
                label={t('Components:Components.NavigationMenu.Analytics')}
                icon={<img src={AnalyticsIconImg} alt="Перейти в аналитику" />}
                pathToGo={pathAnalytics}
                isDisabled={true}
            />
            <NavButton
                label={t('Components:Components.NavigationMenu.MarketRePlay')}
                icon={<img src={MarketRePlayIcon} alt="Перейти в реплей рынка" />}
                pathToGo={pathMarketRePlay}
                isDisabled={true}
            /> */}
            <div className='user-logo-container'  onClick={() => { navigate(loginPath) }}>
                <div className='icon-container'>
                    <img src={userIcon}></img>
                </div>

                <div className='label'>{t('Common:Common.Components.Logo.user')}</div>
            </div>
        </div>
    );
}


NavigationMenu.displayName = 'NavigationMenu';
