// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instrumentPriceContainer {
  display: flex;
  flex-direction: column;
}
.instrumentPriceContainer .price, .instrumentPriceContainer .price-name {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  color: #E7F5FF;
  white-space: nowrap;
}
.instrumentPriceContainer .price {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Components/HeaderMenu/TradingConnections/InstrumentPriceCell/ConnectionPriceCell.scss","webpack://./src/Common/Styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;AAGI;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCCQ;EDAR,mBAAA;AADR;AAII;EACI,gBAAA;AAFR","sourcesContent":["@import '../../../../Common/Styles/colors.scss';\n\n.instrumentPriceContainer {\n    display: flex;\n    flex-direction: column;\n\n    .price, .price-name {\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 11px;\n        color: $color-blue-08;\n        white-space: nowrap;\n    }\n\n    .price {\n        font-weight: 700;\n    }\n}\n","$color-blue-01: #8FBCFB;\n$color-blue-02: #001c36;\n$color-blue-03: #49B6FF;\n$accent: #49B6FF;\n$color-blue-03_075: rgba(73, 182, 255, 0.75);\n$color-blue-04: #AED4FF;\n$color-blue-05: #01283c;\n$color-blue-06: #0F3A5D;\n$accent-bg: #0F3A5D;\n\n$blue-button-fill: #3D93CC;\n$color-blue-07: #0C202E;\n$color-blue-08: #E7F5FF;\n\n$color-gray-01: rgba(157, 172, 202, 0.5);\n$color-gray-02: #20232F;\n\n$ratio-color: #FDE725;\n$color-yellow-01: #D49A04;\n$color-yellow-02: #FDCB48;\n$accent-active: #FDCB48;\n$color-yellow-03: #AC7F09;\n$accent-active-bg: #2E2819;\n\n$color-coral-01: #FF6868;\n\n$color-red-01: #FF0000;\n\n$color-green-01: #39AB1D;\n\n$color-snowy: #ffffff;\n\n$color-black: #000000;\n\n$btn-green-light: #24B232;\n$btn-green-dark: #1A8024;\n$btn-green-shadow: #197F24;\n$btn-green-hr: #14661D;\n\n$btn-red-light: #B22424;\n$btn-red-dark: #7F1919;\n$btn-red-shadow: #7F1919;\n$btn-red-hr: #661414;\n\n$color-passive: #92AEC1;\n\n$light-block-bg: #123248;\n$dark-block-bg: #0A1F2E;\n$dark-block-shadow: #0C2334;\n\n$panel-border-color: #3387C2;\n$panel-light-bg: #153851;\n$panel-bg: #0D273A;\n\n\n$red-candle-color: #ff0000;\n$green-candle-color: #39ab1d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
