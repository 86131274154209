// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-scale-panel {
  position: absolute;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: calc(100% - 70px);
  bottom: 0;
  cursor: col-resize;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  z-index: 1;
  height: 20px;
}
.time-scale-panel .value {
  font-size: 12px;
  display: inline-block;
  color: #d1f7ff;
  font-size: 12px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Modules/CanvasChart/SCI/TimeScale/TimeScale.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,wBAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,0CAAA;EACA,0BAAA;EACA,UAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;EACA,qBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACR","sourcesContent":[".time-scale-panel {\n    position: absolute;\n    display: flex;\n    justify-content: space-between;\n    overflow: hidden;\n    width: calc(100% - 70px);\n    bottom: 0;\n    cursor: col-resize;\n    overflow: hidden;\n    background-color: rgba(255, 255, 255, 0.1);\n    backdrop-filter: blur(2px);\n    z-index: 1;\n    height: 20px;\n\n    .value {\n        font-size: 12px;\n        display: inline-block;\n        color: #d1f7ff;\n        font-size: 12px;\n        font-weight: 500;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
