import {AnalyticsPage} from "./AnalyticsPage";
import Dashboard from "../Dashboard/Dashboard";
import {SettingsPage} from "./SettingsPage";
import React from "react";
import {ConnectorsPage} from "./ConnectorsPage";
import ContactPage from "./ContactPage";
import MarketReplayPage from "./MarketReplayPage";
import NoPage from "./NoPage";
import {Registration} from "./Authorization/Registartion";
import {Login} from "./Authorization/Login";
import {ResetPassword} from "./Authorization/ResetPassword";
import Chart from "../Modules/CanvasChart/SCI/Chart";
import {TradingPage} from "./TradingPage";
import OrderPage from "./OrderPage";
import { ForgotPassword } from "./Authorization/ForgotPassword";

interface NavItem {

    // The path to match in the URL
    path: string;

    // The label to display in the navigation
    label: string;

    // The component to render when this item is selected
    element: React.ComponentType<any>;

    // Hide this item from the navigation
    hide?: boolean;

    // Child items to display in a dropdown
    children?: NavItem[] | null;
}
// <Route path={pathHome} element={<MainChart/>}/>
// <Route path={trading} element={<>trad ing</>}/>
// <Route path={statistic} element={<>statistic</>}/>
// <Route path={personalArea} element={<>personal area</>}/>
// <Route path={canvasDemo} element={<CanvasDemo/>}/>
// <Route path={canvasChart} element={<CanvasChart/>}/>
// <Route path={sciChart} element={<Chart/>} />
//
// <Route path={pathTechAnalysis} element={<TechAnalysis/>}/>
// <Route path={pathTrading} element={pathTrading}/>
// <Route path={pathAnalytics} element={<>pathAnalytics</>}/>
// <Route path={pathMarketRePlay} element={<>pathMarketRePlay</>}/>
// <Route path={orderBook} element={<>orderBook</>}/>
// <Route path={indicators} element={<>indicators</>}/>
// <Route path={tapePrints} element={<>tapePrints</>}/>
// <Route path={correlationCharts} element={<>correlationCharts</>}/>
// <Route path={riskManagement} element={<>riskManagement</>}/>
// <Route path={loginPath} element={<Login/>}/>
// <Route path={registrationPath} element={<Registration/>}/>
// <Route path={passwordForgotPath} element={<ForgotPassword/>}/>
// <Route path={passwordResetPath} element={<ResetPassword/>}/>
// <Route path="*" element={<NoPage/>}/>

export const registrationPath = '/user/register';
export const loginPath = '/user/login';
export const resetPasswordPath = '/user/resetPassword';
export const forgotPasswordPath = '/user/forgotPassword';

export const Navigation: NavItem[] = [
    // Home page
    {path: '/', label: 'Dashboard', element: Dashboard, hide: true},

    // Main navigation items
    {
        path: '/trading', label: 'Trading', element: TradingPage, children: [
            // Trade history
            {path: '/trading/history', label: 'History', element: ConnectorsPage},
            {path: '/trading/orders', label: 'History', element: OrderPage},
        ]
    },
    {path: '/statistics', label: 'Statistics', element: Chart},
    {path: '/analytics', label: 'Analytics', element: AnalyticsPage},
    {path: '/market-replay', label: 'Market Replay', element: MarketReplayPage},
    {path: registrationPath, label: 'Register', element: Registration, hide: true},
    {path: loginPath, label: 'Login', element: Login, hide: true},
    {path: resetPasswordPath, label: 'Register', element: ResetPassword, hide: true},
    {path: forgotPasswordPath, label: 'Register', element: ForgotPassword, hide: true},

    // User registration
    {
        path: '/user', label: 'User', hide: true, element: NoPage, children: [
            {path: '/user/register', label: 'Register', element: Registration},
            {path: '/user/reset', label: 'Reset-Password', element: ResetPassword},
            {path: '/user/login', label: 'Login', element: Login},
            {path: '/user/profile', label: 'User profile', element: SettingsPage},
        ]
    },

    // User owned navigation items
    {path: '/settings', label: 'Settings', hide: true, element: SettingsPage},
    {path: '/orders', label: 'Orders', hide: true, element: OrderPage},
    {path: '/connectors', hide: true, label: 'Connectors', element: ConnectorsPage},

    // Informational navigation items
    {path: '/contact', label: 'Contact', element: ContactPage},
    {path: '/about', label: 'About', element: ContactPage},
    {path: '/terms', label: 'Terms', element: ContactPage},
];

// Search the navigation items for a path
export const findByPath = (path: string): NavItem | null => {

    const find = (items: NavItem[]): NavItem | null => {
        for (const item of items) {
            if (item.path === path) {
                return item;
            }
            if (item.children) {
                const found = find(item.children);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    return find(Navigation);
}