import { useEffect, useState } from "react";
import "./CandlestickChart.scss";

import CandlestickChart from "./CandlestickChart";
import moment from "moment";
import { DataProvider } from "./ExampleData/dataProvider";
import { Exchange, Symbol } from "./entities";
import { ApiCandleInterval } from "../../../services/api";

export interface Candle {
    close: number;
    date: number;
    high: number;
    low: number;
    open: number;
    volume: number;
}

export default function Chart() {
    const [currentCandle, setCurrentCandle] = useState<Candle>();
    const [dateFrom, setDateFrom] = useState<number>( moment().utc().subtract(1000, 'minutes').unix());
    const [dateTo, setDateTo] = useState<number>(moment().utc().add(10, 'minutes').unix());
    const [min, setMin] = useState<number>(moment().utc().subtract(100, 'minutes').unix());
    const [max, setMax] = useState<number>(dateTo);
    const exchange = Exchange.binance;
    const symbol = Symbol.BTCUSDT;
    const interval = ApiCandleInterval.M1;

    const updateCurrentCandle = async () => {
        const end = moment().utc().unix();
        const start = moment().utc().subtract(1, 'minutes').unix();

        const candle = (await DataProvider.GetCandles(start, end, exchange, symbol, interval)).sort((c1, c2) => (c1.date > c2.date) ? 1 : -1)[0];

        if (candle == null) {
            return;
        }

        setCurrentCandle(candle);
    }

    useEffect(() => {
        setInterval(() => {
            updateCurrentCandle()
        }, 500)
    }, []);

    return (
        <CandlestickChart dateFrom={dateFrom} dateTo={dateTo} min={min} max={max} currentCandle={currentCandle} exchange={exchange} interval={interval} symbol={symbol}></CandlestickChart>
    );
}