import { Container } from "@mui/material";

export function ConnectorsPage() {
    return  <Container maxWidth={false} disableGutters sx={{ height: 'calc(100vh - 64px)' }}>
        <iframe
            src="https://jade-blancmange-f8f114.netlify.app/"
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
            }}
            title="External Content"
        />
    </Container>
}