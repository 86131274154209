import axios from "axios";
import Cookies from 'js-cookie';
import { UserIdCookie } from "./constants";

// Service for user profiling to be used by redux actions
export class UserProfile {

    private readonly baseUrl: string;

    private static instance: UserProfile;

    constructor(baseUrl: string = "https://back.markets-platform.com") {
        this.baseUrl = baseUrl;
    }

    /**
     * Register user
     * @param email
     * @param password
     */
    signUp = (email: string, password: string) => {
        let res = axios.post<{ message: string, hash: string }>(this.baseUrl + '/user/email/register', {email, password});

        // TODO: refactor automated confirmation
        res.then((response) => {
            return this.confirm(response.data.hash)
        });

        return res;
    }

    /**
     * Login user
     * @param email
     * @param password
     *  Response: {
     *         "message": "Login successful",
     *             "session_id": "8b1a9953c4611296a827abf8c47804d7",
     *             "token": "8b1a9953c4611296a827abf8c47804d7"
     *     }
     */
    signIn = async (email: string, password: string) => {
        const response = await axios.post<{ message: string, hash: string, sessionId: string }>(this.baseUrl + '/user/email/login', {email, password});
        Cookies.set(UserIdCookie, response.data.sessionId);
        window.location.href = "/"
        return response;
    }

    /**
     * Logout user
     * @param email
     * @param password
     */
    signOut = async (email: string, password: string) => {
        // Remove the cookie
        Cookies.set(UserIdCookie, '');

        // Return user to /user/login
        window.location.href = '/user/login';

        // Return promise
        return Promise.resolve();
    }

    resetPassword = (email: string) =>
        axios.post<{ message: string }>(this.baseUrl + '/user/email/reset', {email})

    changePassword = (email: string, new_password: string, confirm_password: string) =>
        axios.post<{ message: string }>(this.baseUrl + '/user/email/change',
            {email, new_password, confirm_password}
        )

    confirm = (hash: string) => {
        axios.get<{ message: string }>(this.baseUrl + '/user/email/confirm?h=' + hash, {})
        Cookies.set('userId', hash);
        window.location.href = '/';
    }

    // Singleton pattern
    static getInstance() {
        if (!this.instance) {
            this.instance = new UserProfile();
        }
        return this.instance;
    }

    static resetPassword(email: string) {
        return this.getInstance().resetPassword(email);
    }

    static changePassword(email: string, new_password: string, confirm_password: string) {
        return this.getInstance().changePassword(email, new_password, confirm_password);
    }

    static signUp(email: string, password: string) {
        return this.getInstance().signUp(email, password);
    }

    static signIn(email: string, password: string) {
        return this.getInstance().signIn(email, password);
    }

    /**
     * Confirm user email
     *
     * @param hash from email
     */
    static confirm(hash: string) {
        return this.getInstance().confirm(hash);
    }
}

