import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { forgotPasswordPath, loginPath, Navigation, registrationPath, resetPasswordPath } from './Navigation';
import Layout from './Layout';
import NoPage from './NoPage';

const renderRoutes = (routes: typeof Navigation) => {
    return routes.map((route) => {
        if (route.children) {
            return (
                <Route key={route.path} path={route.path} element={<route.element />}>
                    {renderRoutes(route.children)}
                </Route>
            );
        }
        return <Route key={route.path} path={route.path} element={<route.element />} />;
    });
};

export const Router: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        document.body.className = '';

        if (currentPath === registrationPath ||
            currentPath === loginPath || 
            currentPath === resetPasswordPath || 
            currentPath === forgotPasswordPath
        ) {
            document.body.classList.add('auth-page');
        }

        // else if (currentPath === '/about') {
        //     document.body.classList.add('about-page');
        // }
    }, [location]);

    return (
        <Routes>
            <Route element={<Layout />}>
                {renderRoutes(Navigation)}
                <Route path="*" element={<NoPage />} />
            </Route>
        </Routes>
    );
}; 