import { Moment } from "moment";
import { DATE_FORMAT, TimeFrame } from "../constants";
import { UCandle } from "../../../CandlesClient/models/Candle";
import moment from "moment";

export const getSecondsCount = (timeFrame: TimeFrame, timeCount: number): number => {
    switch (timeFrame) {
        case TimeFrame.seconds:
            return timeCount;
        case TimeFrame.minutes:
            return timeCount * 60;
        case TimeFrame.hours:
            return timeCount * 60 * 60;
        case TimeFrame.days:
            return timeCount * 60 * 60 * 8;
        default:
            return timeCount;
    }
}

export const getChangedDate = (direction: boolean, candlesCount: number, oldDate: Moment, timeFrame: TimeFrame): Moment => {
    return direction ? oldDate.clone().add(candlesCount, timeFrame) : oldDate.clone().subtract(candlesCount, timeFrame);
}

export const timeStampToDateString = (timeStamp: number): string => {
    if (timeStamp == null || timeStamp == 0) {
        return '';
    }

    return moment.unix(timeStamp / 1000).format(DATE_FORMAT)
}

export const getDateStringByDirection = (direction: boolean, candles: UCandle[]): string => {
    return direction ? candles.slice(-1)[0].timeClose : candles[0].timeClose;
}

export const getDateStringByCandlesCount = (candlesCount: number, candlesCountToChange: number, date: Moment, timeFrame: TimeFrame): string => {
    let dif = (candlesCount - candlesCountToChange) / 2;
    const direction = candlesCountToChange > candlesCount;

    if (dif < 0) {
        dif *= -1;
    }

    const dateFrom = getChangedDate(!direction, dif, date, timeFrame);

    return dateFrom.format(DATE_FORMAT);
}
