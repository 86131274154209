import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import '../styles.scss';
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../../../services/userProfile";
import cn from "classnames";
import checkIcon from '../icons/ph-check.svg'
import { useDispatch } from "react-redux";
import { showModalPopup } from "../../../Store/action-creators";
import { PopupTitle } from "../../../Store/models";

export enum inputType {
    password = 'password',
    email = 'email',
    text = 'text'
}

export const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false)

    const [email, setEmail] = useState('');
    const [emailValid, setEmaiValid] = useState<boolean | null>(null);

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleEmailValidation = () => {
        setEmaiValid(emailPattern.test(email));
    }

    const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handleEmailValidation();

        if (!emailValid) {
            return;
        }

        setIsLoading(true);

        UserProfile.resetPassword(email)
            .then((response) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.notification,
                    message: response.data.message
                }));
            })
            .catch((error: any) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.error,
                    message: error.message
                }));
            });
    };

    return (
        <div id="authPage">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-fields">
                        <div className="page-title">Reset Password ?</div>
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.enter-e-mail')}</span>
                            <input
                                type="email"
                                disabled={isLoading}
                                className={"email-input "
                                    + (emailValid === false ? 'error' : '')
                                    + (emailValid ? 'valid' : '')}
                                onBlur={handleEmailValidation}
                                onChange={handleEmailChange} />
                            {emailValid &&
                                <img className="input-icon" src={checkIcon} />
                            }
                        </div>
                        <button disabled={isLoading} className={cn({ "button-loader": isLoading, "sign-up": true })}>
                            {!isLoading && "Continue"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

