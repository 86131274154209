import React, {useState} from "react";
import { Container, Typography, Paper, Grid, Box, Icon } from '@mui/material';
import {styled} from "@mui/material/styles";
// import { LineChart, ArrowUpCircle, ArrowDownCircle } from 'lucide-react';

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

export const TradingPage = () => {
    const [connectors, setConnectors] = useState([
        {
            id: 1,
            name: 'Binance Connector',
            provider: 'binance',
            accessKey: 'binance-access-key',
            secretKey: 'binance-secret-key',
            active: true
        },
        {
            id: 2,
            name: 'Bitfinex Connector',
            provider: 'bitfinex',
            accessKey: 'bitfinex-access-key',
            secretKey: 'bitfinex-secret-key',
            active: false
        },
        {
            id: 3,
            name: 'Another Connector',
            provider: 'binance',
            accessKey: 'another-access-key',
            secretKey: 'another-secret-key',
            active: true
        },
    ]);
    const [editingId, setEditingId] = useState<number | null>(null);

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" component="h1" gutterBottom>
                Trading Dashboard
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper sx={{p: 3, textAlign: 'center', height: '100%'}}>
                        <Box sx={{color: 'success.main', mb: 2}}>
                            Up
                        </Box>
                        <Typography variant="h6" gutterBottom>
                            Total Profit
                        </Typography>
                        <Typography variant="h4" color="success.main">
                            +$12,458.32
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Paper sx={{p: 3, textAlign: 'center', height: '100%'}}>
                        <Box sx={{color: 'primary.main', mb: 2}}>
                            Down
                        </Box>
                        <Typography variant="h6" gutterBottom>
                            Active Trades
                        </Typography>
                        <Typography variant="h4">
                            8
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Paper sx={{p: 3, textAlign: 'center', height: '100%'}}>
                        <Box sx={{color: 'error.main', mb: 2}}>
                            Up
                        </Box>
                        <Typography variant="h6" gutterBottom>
                            Total Loss
                        </Typography>
                        <Typography variant="h4" color="error.main">
                            -$3,271.45
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{p: 3, mt: 3}}>
                        <Typography variant="h6" gutterBottom>
                            Recent Trading Activity
                        </Typography>
                        <Typography color="text.secondary">
                            Connect to exchanges to view your trading activity
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
