import React from "react";
import {Button, Container, Grid, TextField, Typography} from "@mui/material";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

export default function ContactPage() {
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Add your form submission logic here
        console.log('Form submitted:', formData);
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Contact Us
            </Typography>
            <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={4}
                        required
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Send
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LoadScript
                        googleMapsApiKey="AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg"
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '400px',
                            }}
                            center={{
                                lat: 40.4168,
                                lng: -3.7038,
                            }}
                            zoom={10}
                        >
                            <Marker
                                position={{
                                    lat: 40.4168,
                                    lng: -3.7038,
                                }}
                            />
                        </GoogleMap>
                    </LoadScript>
                </Grid>
            </Grid>
        </Container>
    );
}