// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-container .wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.canvas-container .static-mode-togler {
  position: absolute;
  display: flex;
  width: 70px;
  height: 20px;
  bottom: 0;
  right: 0;
  justify-content: center;
  background-color: #121b23;
  z-index: 10;
  cursor: pointer;
  align-items: center;
  border-radius: 5px;
  border: 2px solid white;
}
.canvas-container .static-mode-togler.active {
  border-color: aquamarine;
}
.canvas-container .static-mode-togler img {
  width: 23px;
  height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/CanvasChart/SCI/CandlestickChart.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;AAAR;AAGI;EACI,kBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,QAAA;EACA,uBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;AADR;AAGQ;EACI,wBAAA;AADZ;AAIQ;EACI,WAAA;EACA,YAAA;AAFZ","sourcesContent":[".canvas-container {\n    .wrapper {\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        top: 0;\n        left: 0;\n    }\n\n    .static-mode-togler {\n        position: absolute;\n        display: flex;\n        width: 70px;\n        height: 20px;\n        bottom: 0;\n        right: 0;\n        justify-content: center;\n        background-color: #121b23;\n        z-index: 10;\n        cursor: pointer;\n        align-items: center;\n        border-radius: 5px;\n        border: 2px solid white;\n\n        &.active {\n            border-color: aquamarine;\n        }\n\n        img {\n            width: 23px;\n            height: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
