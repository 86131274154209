// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-scale-panel {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - 20px);
  width: 70px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  cursor: row-resize;
  z-index: 1;
}
.price-scale-panel .tick {
  position: absolute;
  text-align: center;
  font-size: 12px;
  color: #d1f7ff;
  font-size: 12px;
  font-weight: 500;
  display: block;
  width: 100%;
}
.price-scale-panel .tick.current {
  border: 2px solid #fdcb48;
  border-radius: 5px;
  background-color: #2e2819;
  z-index: 1;
}
.price-scale-panel .tick.current .back-arrow {
  width: 18px;
  height: 18px;
  position: absolute;
  border: 2px solid #fdcb48;
  border-radius: 5px;
  left: -25px;
  top: -2px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Modules/CanvasChart/SCI/PriceScale/PriceScale.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,0CAAA;EACA,0BAAA;EACA,kBAAA;EACA,UAAA;AACJ;AACI;EACI,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;AACR;AACQ;EACI,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,UAAA;AACZ;AACY;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AAChB","sourcesContent":[".price-scale-panel {\n    position: absolute;\n    right: 0;\n    top: 0;\n    height: calc(100% - 20px);\n    width: 70px;\n    text-align: center;\n    background-color: rgba(255, 255, 255, 0.1);\n    backdrop-filter: blur(2px);\n    cursor: row-resize;\n    z-index: 1;\n\n    .tick {\n        position: absolute;\n        text-align: center;\n        font-size: 12px;\n        color: #d1f7ff;\n        font-size: 12px;\n        font-weight: 500;\n        display: block;\n        width: 100%;\n\n        &.current {\n            border: 2px solid #fdcb48;\n            border-radius: 5px;\n            background-color: #2e2819;\n            z-index: 1;\n\n            .back-arrow {\n                width: 18px;\n                height: 18px;\n                position: absolute;\n                border: 2px solid #fdcb48;\n                border-radius: 5px;\n                left: -25px;\n                top: -2px;\n                cursor: pointer;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
