import axios from "axios";

export class UserProfile {
    static signUp = (email: string, password: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/register',
            { email, password }
        )

    static signIn = (email: string, password: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/login',
            { email, password }
        )

    static forgotPassword = (email: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/forgot',
            { email }
        )

    static resetPassword = (email: string, new_password: string, confirm_password: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/resetPassword',
            { email, new_password, confirm_password }
        )
}