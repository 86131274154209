import {UserActions, UserAnonymizedAction, UserLoggedInAction} from "../models";

export interface ProfileState {
    profile: Profile | null
    hash: string | null
    userId: string | null
}

interface Profile {
    id: number;
    name: string;
    email: string;
}

const userState = {
    profile: {
        "id": 1,
        "name": "John Doe",
        "email": "test@gmail.com",
    } as Profile,
    userId: null
} as ProfileState;

export const userReducer = (
    state = userState,
    action: UserLoggedInAction | UserAnonymizedAction
): ProfileState => {
    switch (action.type) {
        case UserActions.USER_LOGGED_IN:
            return {
                ...state,
                hash: action.payload.hash,
                userId: action.payload.sessionId
            }
        case UserActions.USER_ANONYMOUS:
            return {
                ...state,
                hash: null,
                profile: null,
                userId: null
            }
        default:
            return {
                ...state,
            }
    }
}