import React from 'react';
import logoIcon from '../../../logo.svg';
import marketsIcon from '../../../markets.svg';

import  "./Logo.scss";

export const Logo: React.FC = () => {
    return (
        <div className="logo-container">
            <img src={logoIcon}></img>
            <img className='app-title' src={marketsIcon}></img>
        </div>
    );
}

Logo.displayName = 'Logo';
