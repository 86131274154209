import { Extremums, UCandle, ZoomExtremums } from "../../../CandlesClient/models/Candle";
import { SettingsValue } from "../../../Store/models";
import { CANDLE_OFFSET, CANVAS_HEIGHT } from "../constants";
import { getScrolledCanlesCount } from "../helper";

export const getMinCandleLow = (candles: UCandle[]): number => {
    if (candles == null || candles.length === 0) {
        return 0;
    }

    let min = Number.MAX_VALUE;

    candles.forEach(candle => {
        if (candle.low < min && candle.id != 0) {
            min = candle.low;
        }
    });

    return min;
}

export const getMaxCandleHigh = (candles: UCandle[]): number => {
    if (candles == null || candles.length === 0) {
        return 0;
    }

    let max = Number.MIN_VALUE;

    candles.forEach(candle => {
        if (candle.high > max && candle.id != 0) {
            max = candle.high;
        }
    });

    return max;
}

export const getZoomExtremums = (lowestPoint: number, highestPoint: number, canvasHeight: number, scrollTop: number,
    direction: boolean, lowestPrice: number, highestPrice: number, selectedPriceScaleSetting: SettingsValue) => {

    switch (selectedPriceScaleSetting) {
        case SettingsValue.PriceDefault:
            return getDefaultExtremums(direction, lowestPoint, highestPoint, scrollTop);
        // case SettingsValue.LastPrice:
        //     return getExtremumsPerLastPrice(direction, lowestPoint, highestPoint, latestPrice, canvasHeight);

        case SettingsValue.MidlePrice:
            return getCenteredExtremums(direction, lowestPrice, highestPrice, lowestPoint, highestPoint, canvasHeight);

    }

    return getDefaultExtremums(direction, lowestPoint, highestPoint, scrollTop);
}

const getDefaultExtremums = (direction: boolean, lowestPoint: number, highestPoint: number, scrollTop: number): ZoomExtremums => {
    const dif = highestPoint - lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas

    return {
        newLowestPoint: direction ? lowestPoint + valueToChange : lowestPoint - valueToChange,
        newHighestPoint: direction ? highestPoint - valueToChange : highestPoint + valueToChange,
        newScrollTop: scrollTop
    }
}

// const getExtremumsPerLastPrice = (direction: boolean, lowestPoint: number, highestPoint: number, latestPrice: number, canvasHeight: number): ZoomExtremums => {
//     const dif = highestPoint - lowestPoint;
//     const valueToChange = dif / 25; // 4 % size of canvas
//     const topValue = latestPrice + dif / 2;
//     const bottomValue = latestPrice - dif / 2;

//     return {
//         newLowestPoint: direction ? bottomValue + valueToChange : bottomValue - valueToChange,
//         newHighestPoint: direction ? topValue - valueToChange : topValue + valueToChange,
//         newScrollTop: CANVAS_HEIGHT / 2 - canvasHeight / 2
//     }
// }

const getCenteredExtremums = (direction: boolean, lowestPrice: number, highestPrice: number, lowestPoint: number, highestPoint: number, canvasHeight: number): ZoomExtremums => {
    const dif = highestPoint - lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas
    const midlePrice = highestPrice - ((highestPrice - lowestPrice) / 2);
    const topValue = midlePrice + dif / 2;
    const bottomValue = midlePrice - dif / 2;

    return {
        newLowestPoint: direction ? bottomValue + valueToChange : bottomValue - valueToChange,
        newHighestPoint: direction ? topValue - valueToChange : topValue + valueToChange,
        newScrollTop: CANVAS_HEIGHT / 2 - canvasHeight / 2
    }
}

export const getFloatExtremums = (candles: UCandle[], canvasHeight: number): Extremums => {
    if (candles == null || candles.length === 0) {
        return {
            lowestPrice: 0,
            highestPrice: 0,
            lowestPoint: 0,
            highestPoint: 0,
            scrollTop: 0
        }
    }

    let lowestPrice = getMinCandleLow(candles);
    let highestPrice = getMaxCandleHigh(candles);

    const dif = highestPrice - lowestPrice;
    const viewportCanvasPoint = canvasHeight / dif;
    const gap = (CANVAS_HEIGHT - canvasHeight) / 2 / viewportCanvasPoint;

    let highestPoint = highestPrice + gap;
    let lowestPoint = lowestPrice - gap;

    const pixelsPerpoint = canvasHeight / dif;
    const scrollTop = pixelsPerpoint * (highestPoint - highestPrice);

    return {
        lowestPrice,
        highestPrice,
        lowestPoint,
        highestPoint,
        scrollTop
    }
}

export const checkAvailableExtremums = (candleIn: UCandle, candleOut: UCandle, highest: number, lowest: number): boolean => {
    const dif = (highest - lowest) / 3;
    const high = highest - dif;
    const low = lowest + dif;

    return candleOut?.high >= high || candleOut?.low <= low || candleIn?.high >= high || candleIn?.low <= low;
}

export const getStaticExtremums = (candles: UCandle[], canvasWidth: number, scaleIndex: number, scrollLeft: number, canvasHeight: number, spaceGap: number): Extremums => {
    const candlesCountOnViewPort = canvasWidth / (CANDLE_OFFSET * scaleIndex);
    const scrolledCandlesCount = getScrolledCanlesCount(scrollLeft - spaceGap, scaleIndex);
    const startIndex = Math.round(scrolledCandlesCount);
    const endIndex = Math.round(startIndex + candlesCountOnViewPort);
    const candlesToCalculate = candles.slice(startIndex, endIndex);

    let lowestPrice = getMinCandleLow(candlesToCalculate);
    let highestPrice = getMaxCandleHigh(candlesToCalculate);

    const dif = highestPrice - lowestPrice;
    const viewportCanvasPoint = canvasHeight / dif;
    const gap = (CANVAS_HEIGHT - canvasHeight) / 2 / viewportCanvasPoint;

    let highestPoint = highestPrice + gap;
    let lowestPoint = lowestPrice - gap;

    console.log(lowestPrice, highestPrice, highestPoint, lowestPoint);

    const pixelsPerpoint = canvasHeight / dif;
    const scrollTop = pixelsPerpoint * (highestPoint - highestPrice);

    return {
        lowestPrice,
        highestPrice,
        lowestPoint,
        highestPoint,
        scrollTop
    }
}