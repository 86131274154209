import React from 'react';
import { useTranslation } from "react-i18next";

import { NavButton } from "../../Common/Components/NavButton/NavButton";
import { orderBook, indicators, tapePrints, correlationCharts, riskManagement } from "../../Routes/Routes";
import TradingPanel from "../../Common/Components/Icons/ButtonIcons/TradingPanel.svg";
import OrderBook from "../../Common/Components/Icons/ButtonIcons/OrderBook.svg";
import Indicators from "../../Common/Components/Icons/ButtonIcons/Indicators.svg";
import TapePrints from "../../Common/Components/Icons/ButtonIcons/TapePrints.svg";
import CorrelationCharts from "../../Common/Components/Icons/ButtonIcons/CorrelationCharts.svg";
import RiskManagement from "../../Common/Components/Icons/ButtonIcons/RiskManagement.svg";
import { TradingConnections } from "../HeaderMenu/TradingConnections";
import useComponentVisible from "../../Hooks/useComponentVisible";

import "./ChartsMenu.scss";
import { TradePanel } from './TradePanel';

export const ChartsMenu: React.FC = () => {

    const { t } = useTranslation();

    const tradingConnections = useComponentVisible(false);
    const tradePanel = useComponentVisible(false);

    const handleClickTradingConnectionsMenu = () => {
        tradingConnections.setIsComponentVisible(prev => !prev)
    }

    const onTradingConnectionsClose = () => {
        tradingConnections.setIsComponentVisible(false)
    }

    const handleClickTradePanelMenu = () => {
        tradePanel.setIsComponentVisible(prev => !prev)
    }

    const onTradePanelClose = () => {
        tradePanel.setIsComponentVisible(false)
    }

    return (
        <div className="right-menu-container">
            <div ref={tradingConnections.componentRef} className="popup-container connection-popup-container">
                {tradingConnections.isComponentVisible && (
                    <TradingConnections onClose={onTradingConnectionsClose} />
                )}
            </div>
            <div ref={tradePanel.componentRef} className="trade-panel-container">
                {tradePanel.isComponentVisible && (
                    <TradePanel onClose={onTradePanelClose} />
                )}
            </div>
            <div className='menu'>
                <NavButton
                    label={t('Components:Components.NavigationMenu.TradingPanel')}
                    icon={<img src={TradingPanel} alt="TradingPanel" />}
                    onClick={handleClickTradePanelMenu}
                />
                {/* <NavButton
                    label={t('Components:Components.NavigationMenu.OrderBook')}
                    icon={<img src={OrderBook} alt="OrderBook" />}
                    pathToGo={orderBook}
                    isDisabled
                />
                <NavButton
                    label={t('Components:Components.NavigationMenu.Indicators')}
                    icon={<img src={Indicators} alt="Indicators" />}
                    pathToGo={indicators}
                    isDisabled
                />
                <NavButton
                    label={t('Components:Components.NavigationMenu.TapePrints')}
                    icon={<img src={TapePrints} alt="TapePrints" />}
                    pathToGo={tapePrints}
                    isDisabled
                />
                <NavButton
                    label={t('Components:Components.NavigationMenu.CorrelationCharts')}
                    icon={<img src={CorrelationCharts} alt="CorrelationCharts" />}
                    pathToGo={correlationCharts}
                    isDisabled
                />
                <NavButton
                    label={t('Components:Components.NavigationMenu.RiskManagement')}
                    icon={<img src={RiskManagement} alt="RiskManagement" />}
                    pathToGo={riskManagement}
                    isDisabled
                /> */}
                <NavButton
                    label={t('Components:Components.NavigationMenu.TradingConnections')}
                    icon={<img src={TapePrints} alt="TradingConnections" />}
                    onClick={handleClickTradingConnectionsMenu}
                />
            </div>

        </div>

    );
}


ChartsMenu.displayName = 'ChartsMenu';
