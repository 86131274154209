import React, { LegacyRef } from 'react';
import "./PriceScale.scss";
import BackArrowIcon from '../../../../Common/Components/Icons/BackArrowIcon.png';

const offsetLimit = { min: 0, max: 95 };

interface PriceScaleProps {
    currentValue?: number;
    minPrice: number;
    maxPrice: number;
    priceTicks: number[];
    elementRef: LegacyRef<HTMLDivElement>;
    backToCurrent: Function;
}

interface PriceTick {
    value: number;
    offset: number;
}

const PriceScale: React.FC<PriceScaleProps> = ({ minPrice, maxPrice, priceTicks, elementRef, backToCurrent, currentValue }) => {
    const getCurrentTick = (): PriceTick => {
        let offset = (1 - ((currentValue || 0) - minPrice) / (maxPrice - minPrice)) * 100;

        if (offset < offsetLimit.min) {
            offset = offsetLimit.min;
        } else if (offset > offsetLimit.max) {
            offset = offsetLimit.max;
        }

        return {
            value: currentValue || 0,
            offset: offset
        }
    }

    const generateTicks = (): PriceTick[] => {
        const ticks: PriceTick[] = [];

        priceTicks.forEach(tick => {
            ticks.push({ value: tick, offset: (1 - ((tick || 0) - minPrice) / (maxPrice - minPrice)) * 100 });
        });

        return ticks;
    }
    
    const ticks = generateTicks();
    const currentTick = getCurrentTick();

    const handleArrowClick = (event: any) => {
        event.stopPropagation();
        backToCurrent();
    }

    return (
        <div className="price-scale-panel" ref={elementRef}>
            <div
                key={0}
                className="tick current"
                style={{
                    top: `${currentTick.offset}%`,
                }}
            >
                <div>{currentTick.value}</div>
                <img className="back-arrow" src={BackArrowIcon} onClick={handleArrowClick} />
            </div>
            {
                ticks.map((tick, index) => (
                    <div
                        key={index}
                        className="tick"
                        style={{
                            top: `${tick.offset}%`,
                        }}
                    >
                        <div>{tick.value} </div>
                    </div>
                ))}
        </div>
    );
};

export default PriceScale;



// const getRoundStep = (minPrice: number, maxPrice: number) => {
//     const range = maxPrice - minPrice;

//     if (range >= 1e9) return 1e8;
//     if (range >= 1e6) return 1e4;
//     if (range >= 1e3) return 1e2;
//     if (range >= 1e2) return 10;
//     if (range >= 1e1) return 1;
//     return 1;
// };

// const roundValue = (value: number, step: number) => {
//     return Math.round(value / step) * step;
// };

// const generateTicks = (): PriceTick[] => {
//     const stepValue = (maxPrice - minPrice) / (ticksCount - 1);

//     const ticks: PriceTick[] = [];

//     for (let i = 0; i < ticksCount; i++) {
//         const value = minPrice + stepValue * i;

//         const roundedValue = roundValue(value, getRoundStep(minPrice, maxPrice));

//         // Расчёт смещения
//         const offset = (1 - (roundedValue - minPrice) / (maxPrice - minPrice)) * 100;

//         ticks.push({ value: roundedValue, offset });
//     }

//     return ticks;
// };