import { AddNewCandleAction, CandnleMenuItemAction, ChangeChartSettingAction, ChartScaleAction, ChartStyle, MenuItemAction, ModalPopupAction, PopupData, SettingsValue, TAppState } from "../models";
import { EAppActions } from "../enums";
import { Candle, ChartType } from "../../Models/Candle";
import { getScaleIndexByChartType, getChartTypeByChartStyle, getSelectedChartStyle, getTimeFrameBySelectedChart } from "./helper";
import { TimeFrame } from "../../Modules/Candles/constants";
import { CHART_TYPES_ID } from "../../Components/consts";

export const appReducer = (
    state = initialAppState, action: MenuItemAction | AddNewCandleAction | CandnleMenuItemAction | ChartScaleAction | ModalPopupAction | ChangeChartSettingAction): TAppState => {
    switch (action.type) {
        case EAppActions.CHANGE_APP_COLOR_THEME:
            return {
                ...state,
                lightTheme: !state.lightTheme
            }
        case EAppActions.SELECT_MENU_ITEM:
            return {
                ...state,
                selectedMenuItem: action.payload as string | null
            }
        case EAppActions.SELECT_CHART_TYPE_ITEM:
            const style = action.payload as ChartStyle;

            const chartType: ChartType = getChartTypeByChartStyle(style);
            const selectedChartStyles = state.mainChart.selectedChartStyles;
            selectedChartStyles[chartType] = style;

            return {
                ...state,
                mainChart: {
                    ...state.mainChart,
                    scaleIndex: getScaleIndexByChartType(chartType),
                    customScale: true,
                    selectedChartStyles: selectedChartStyles,
                    activeChartStyle: style
                }
            }
        case EAppActions.CHANGE_CHART_SCALE:
            const si = action.payload as number;

            return {
                ...state,
                mainChart: {
                    ...state.mainChart,
                    customScale: true,
                    scaleIndex: si,
                    activeChartStyle: getSelectedChartStyle(si, state.mainChart.selectedChartStyles)
                }
            }
        case EAppActions.SELECT_CHART_TYPE_FRAME:
            const chartTypeFrameId = action.payload as CHART_TYPES_ID;
            const { timeFrame, scaleIndex, timeCount } = getTimeFrameBySelectedChart(chartTypeFrameId);

            return {
                ...state,
                selectedChartFrame: chartTypeFrameId,
                mainChart: {
                    ...state.mainChart,
                    customScale: false,
                    scaleIndex,
                    activeChartStyle: getSelectedChartStyle(scaleIndex, state.mainChart.selectedChartStyles),
                    timeFrame,
                    timeCount
                }
            }
        case EAppActions.ADD_NEW_CANDLE:
            const candle = action?.payload as Candle;

            if (candle.high === 0 && candle.low === 0) {
                return state
            }

            return {
                ...state,
                candles: state.candles.concat(candle)
            }
        case EAppActions.SHOW_MODAL_POPUP:
            return {
                ...state,
                modalPopupData: action?.payload as PopupData
            }

        case EAppActions.CHANGE_WHEEL_SETTING:
            const wheelSettings = state.mainChart.settings;
            wheelSettings.wheel = action.payload as SettingsValue;

            return {
                ...state,
                mainChart: { ...state.mainChart, settings: wheelSettings }
            }

        case EAppActions.CHANGE_CLUSTER_SETTING:
            const clusterSettings = state.mainChart.settings;
            clusterSettings.wheel = action.payload as SettingsValue;

            return {
                ...state,
                mainChart: { ...state.mainChart, settings: clusterSettings }
            }

        case EAppActions.CHANGE_PRICE_SETTING:
            const priceSettings = state.mainChart.settings;
            priceSettings.wheel = action.payload as SettingsValue;

            return {
                ...state,
                mainChart: { ...state.mainChart, settings: priceSettings }
            }

        default:
            return state;
    }
}

const initialAppState: TAppState = {
    lightTheme: true,
    selectedMenuItem: null,
    selectedChartFrame: CHART_TYPES_ID.M1,
    modalPopupData: null,
    candleStartIndex: 0,
    mainChart: {
        scaleIndex: 1,
        customScale: false,
        selectedChartStyles: {},
        activeChartStyle: ChartStyle.japanesePoor,
        timeCount: 1,
        timeFrame: TimeFrame.minutes,
        settings: {
            wheel: SettingsValue.Zoom,
            priceScale: SettingsValue.PriceDefault,
            clusterPrice: SettingsValue.Visible
        }
    },
    hotKeyCodes: {
        toggleCandleVisibility: 'z',
        toggleChartVisibility: 'x',
        toggleRightMenuVisibility: 'c'
    },
    candles: []
}
