import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: ( theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center',
  },
}));

export default function NavbarBreadcrumbs() {
  function getNavigationPath() {
    let s = window.location.pathname.replace("/", "") || "Home";

    // Capitalize the first letter
    s = s.charAt(0).toUpperCase() + s.slice(1);
    return s;
  }

  return (
      <StyledBreadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextRoundedIcon fontSize="small"/>}
      >
        <Typography variant="body1">Home</Typography>
        <Typography variant="body1" sx={{color: 'text.primary', fontWeight: 600}}>
          {getNavigationPath()}

        </Typography>
    </StyledBreadcrumbs>
  );
}
