// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*::-webkit-scrollbar,
html *::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
    background: rgba(52, 108, 150, 0.2);
    border-radius: 8px;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
    background-color: #346C96;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gBAAgB;EAChB,mCAAmC;EACnC,kCAAkC;AACpC;;;AAGA;;IAEI,UAAU;AACd;AACA;;IAEI,mCAAmC;IACnC,kBAAkB;AACtB;AACA;;IAEI,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":["body {\n  margin: 0;\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n\n*::-webkit-scrollbar,\nhtml *::-webkit-scrollbar {\n    width: 6px;\n}\n*::-webkit-scrollbar-track,\nhtml *::-webkit-scrollbar-track {\n    background: rgba(52, 108, 150, 0.2);\n    border-radius: 8px;\n}\n*::-webkit-scrollbar-thumb,\nhtml *::-webkit-scrollbar-thumb {\n    background-color: #346C96;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
