export enum CHART_TYPES_ID {
    S1 = "S1",
    S5 = "S5",
    S15 = "S15",
    S30 = "S30",
    M1 = "M1",
    M5 = "M5",
    M15 = "M15",
    M30 = "M30",
    H1 = "H1",
    H4 = "H4",
    H8 = "H8",
    H12 = "H12",
    D1 = "D1",
    D3 = "D3",
    D7 = "D7",
    D14 = "D14",
    MN1 = "MN1"
}

export const CHART_TYPES = [
    {
        title: "CLASSIC_CANDLE",
        options: [
            {
                optionTitle: "Seconds",
                subOptions: [
                    {
                        _id: CHART_TYPES_ID.S1,
                        title: "S1"
                    },
                    {
                        _id: CHART_TYPES_ID.S5,
                        title: "S5"
                    },
                    {
                        _id: CHART_TYPES_ID.S15,
                        title: "S15"
                    },
                    {
                        _id: CHART_TYPES_ID.S30,
                        title: "S30"
                    },
                    {
                        _id: "ADDITIONAL_S",
                        title: "...",
                        cssClass: 'additional'
                    }
                ]
            },
            {
                optionTitle: "Minutes",
                subOptions: [
                    {
                        _id: CHART_TYPES_ID.M1,
                        title: "M1"
                    },
                    {
                        _id: CHART_TYPES_ID.M5,
                        title: "M5"
                    },
                    {
                        _id: CHART_TYPES_ID.M15,
                        title: "M15"
                    },
                    {
                        _id: CHART_TYPES_ID.M30,
                        title: "M30"
                    },
                    {
                        _id: "ADDITIONAL_M",
                        title: "...",
                        cssClass: 'additional'
                    }
                ]
            },
            {
                optionTitle: "Hours",
                subOptions: [
                    {
                        _id: CHART_TYPES_ID.H1,
                        title: "H1"
                    },
                    {
                        _id: CHART_TYPES_ID.H4,
                        title: "H4"
                    },
                    {
                        _id: CHART_TYPES_ID.H8,
                        title: "H8"
                    },
                    {
                        _id: CHART_TYPES_ID.H12,
                        title: "H12"
                    },
                    {
                        _id: "ADDITIONAL_H",
                        title: "...",
                        cssClass: 'additional'
                    }
                ]
            },
            {
                optionTitle: "Days",
                subOptions: [
                    {
                        _id: CHART_TYPES_ID.D1,
                        title: "D1"
                    },
                    {
                        _id: CHART_TYPES_ID.D3,
                        title: "D3"
                    },
                    {
                        _id: CHART_TYPES_ID.D7,
                        title: "D7"
                    },
                    {
                        _id: CHART_TYPES_ID.D14,
                        title: "D14"
                    },
                    {
                        _id: "ADDITIONAL_D",
                        title: "...",
                        cssClass: 'additional'
                    }
                ]
            },
            {
                optionTitle: "Month",
                subOptions: [
                    {
                        _id: CHART_TYPES_ID.MN1,
                        title: "MN1"
                    },
                    {
                        _id: "CLASSIC_CANDLE_MN3",
                        title: "MN3"
                    },
                    {
                        _id: "CLASSIC_CANDLE_MN7",
                        title: "MN6"
                    },
                    {
                        _id: "CLASSIC_CANDLE_D14",
                        title: "Y1"
                    },
                    {
                        _id: "ADDITIONAL_MN",
                        title: "...",
                        cssClass: 'additional'
                    }
                ]
            }
        ]
    },
    // {
    //     title: "RANGE",
    //     options: [
    //         {
    //             optionTitle: "Range Classic",
    //             subOptions: [
    //                 {
    //                     _id: "RANGE_CLASSIC_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "RANGE_CLASSIC_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "RANGE_CLASSIC_8",
    //                     title: "8"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Renko",
    //             subOptions: [
    //                 {
    //                     _id: "RENKO_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "RENKO_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "RENKO_8",
    //                     title: "8"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Range Modern",
    //             subOptions: [
    //                 {
    //                     _id: "RANGE_MODERN_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "RANGE_MODERN_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "RANGE_MODERN_8",
    //                     title: "8"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Range Modern 2",
    //             subOptions: [
    //                 {
    //                     _id: "RANGE_MODERN_2_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "RANGE_MODERN_2_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "RANGE_MODERN_2_8",
    //                     title: "8"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Range Modern 3",
    //             subOptions: [
    //                 {
    //                     _id: "RANGE_MODERN_3_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "RANGE_MODERN_3_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "RANGE_MODERN_3_8",
    //                     title: "8"
    //                 },
    //             ]
    //         }
    //     ]
    // },
    // {
    //     title: "REVERSAL",
    //     options: [
    //         {
    //             optionTitle: "Reversal",
    //             subOptions: [
    //                 {
    //                     _id: "REVERSAL_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "REVERSAL_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "REVERSAL_8",
    //                     title: "8"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Reversal Delta",
    //             subOptions: [
    //                 {
    //                     _id: "REVERSAL_DELTA_1",
    //                     title: "1"
    //                 },
    //                 {
    //                     _id: "REVERSAL_DELTA_4",
    //                     title: "4"
    //                 },
    //                 {
    //                     _id: "REVERSAL_DELTA_8",
    //                     title: "8"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Delta",
    //             subOptions: [
    //                 {
    //                     _id: "DELTA_10",
    //                     title: "10"
    //                 },
    //                 {
    //                     _id: "DELTA_25",
    //                     title: "25"
    //                 },
    //                 {
    //                     _id: "DELTA_50",
    //                     title: "50"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Volume",
    //             subOptions: [
    //                 {
    //                     _id: "Volume_50",
    //                     title: "50"
    //                 },
    //                 {
    //                     _id: "Volume_100",
    //                     title: "100"
    //                 },
    //                 {
    //                     _id: "Volume_250",
    //                     title: "250"
    //                 },
    //             ]
    //         }
    //     ]
    // },
    // {
    //     title: "CLUSTER",
    //     options: [
    //         {
    //             optionTitle: "Cluster Volume",
    //             subOptions: [
    //                 {
    //                     _id: "CLUSTER_VOLUME_50",
    //                     title: "50"
    //                 },
    //                 {
    //                     _id: "CLUSTER_VOLUME_100",
    //                     title: "100"
    //                 },
    //                 {
    //                     _id: "CLUSTER_VOLUME_250",
    //                     title: "250"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Speed Chart",
    //             subOptions: [
    //                 {
    //                     _id: "SPEED_CHART_W1",
    //                     title: "W1"
    //                 },
    //                 {
    //                     _id: "SPEED_CHART_MN1",
    //                     title: "MN1"
    //                 },
    //                 {
    //                     _id: "SPEED_CHART_Y1",
    //                     title: "Y1"
    //                 },
    //             ]
    //         },
    //         {
    //             optionTitle: "Quant Chart",
    //             subOptions: [
    //                 {
    //                     _id: "QUANT_CHART_ALL",
    //                     title: "All"
    //                 },
    //                 {
    //                     _id: "QUANT_CHART_S1",
    //                     title: "S1"
    //                 }
    //             ]
    //         },
    //     ]
    // }
]
