export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export interface Range {
    min: number;
    max: number;
}

export interface Point {
    x: number;
    y: number;
}

export enum Exchange {
    binance = "binance"
}

export enum Symbol {
    BTCUSDT = "BTCUSDT"
}

export enum TimeFrame {
    oneMin = "1m"
}

export enum ElementId {
    PriceScale = "DefaultAxisId",
    TimeScale = "DefaultAxisId",
}

export interface ChartEvent {
    priceScaleActive: boolean;
    timeScaleActive: boolean;
    scrollingActive: boolean;
}

export interface UserSettings {
    minPriceGap: number;
    minTimeGap: number;
    priceTickCount: number;
    timeTickCount: number;
    scaleStepPercents: number;
    scrollingStepPercents: number;
    dataLoadVolume: number;
}

export const userConfig: UserSettings = {
    minPriceGap: 1,
    minTimeGap: 1000,
    priceTickCount: 15,
    timeTickCount: 8,
    scaleStepPercents: 2.5,
    scrollingStepPercents: 1,
    dataLoadVolume: 50
}

export const surfaceSeriesId = {
    volume: 0,
    ohlc: 1,
    candleStick: 2,
    avarage20: 3,
    avarage50: 4
}

export enum ChartUserEvent {
    None = "None",
    MouseUp = "MouseUp",
    ScaleX = "ScaleX",
    BackToCurrent = "BackToCurrent"
}