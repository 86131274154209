// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-select-container.inline {
  display: inline-block;
}
.number-select-container .label-container {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
}
.number-select-container .number-select-controls {
  display: inline-flex;
  vertical-align: middle;
}
.number-select-container .number-select-controls .number-select {
  border-radius: 4px;
  border: 0.5px solid #49B6FF;
  color: white;
  background: transparent;
  padding: 4px 7px 5px 7px;
  height: 24px;
}
.number-select-container .number-select-controls .number-select.with-buttons {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.number-select-container .number-select-controls .button-switcher.vertical:not(.wrap) button.blue-button:first-child, .number-select-container .number-select-controls .button-switcher.vertical:not(.wrap) button.golden-button:first-child,
.number-select-container .number-select-controls .button-switcher.vertical:not(.wrap) button.blue-button:last-child, .number-select-container .number-select-controls .button-switcher.vertical:not(.wrap) button.golden-button:last-child {
  height: 12px;
  line-height: 12px;
  padding: 0;
  width: 22px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/NumberSelect/NumberSelect.scss","webpack://./src/Common/Styles/metrics.scss"],"names":[],"mappings":"AAKE;EACE,qBAAA;AAJJ;AAOE;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;AALJ;AAQE;EACE,oBAAA;EACA,sBAAA;AANJ;AAQI;EACE,kBChBc;EDiBd,2BAAA;EACA,YAAA;EACA,uBAAA;EACA,wBAAA;EACA,YAAA;AANN;AAQM;EACE,0BAAA;EACA,6BAAA;AANR;AAUI;;EAEE,YAAA;EACA,iBAAA;EACA,UAAA;EACA,WAAA;EAEA,yBAAA;EACA,4BAAA;AATN","sourcesContent":["@import '../../../Common/Styles/colors';\n@import '../../../Common/Styles/metrics';\n\n.number-select-container {\n\n  &.inline {\n    display: inline-block;\n  }\n\n  .label-container {\n    display: flex;\n    margin-bottom: 5px;\n    justify-content: space-between;\n  }\n\n  .number-select-controls {\n    display: inline-flex;\n    vertical-align: middle;\n\n    .number-select {\n      border-radius: $btn-border-radius;\n      border: 0.5px solid $accent;\n      color: white;\n      background: transparent;\n      padding: 4px 7px 5px 7px;\n      height: 24px;\n\n      &.with-buttons {\n        border-top-right-radius: 0;\n        border-bottom-right-radius: 0;\n      }\n    }\n\n    .button-switcher.vertical:not(.wrap) button.blue-button:first-child, .button-switcher.vertical:not(.wrap) button.golden-button:first-child,\n    .button-switcher.vertical:not(.wrap) button.blue-button:last-child, .button-switcher.vertical:not(.wrap) button.golden-button:last-child {\n      height: 12px;\n      line-height: 12px;\n      padding: 0;\n      width: 22px;\n\n      border-top-left-radius: 0;\n      border-bottom-left-radius: 0;\n    }\n  }\n}","// common component metrics\n$navigation-menu-width: 75px;\n$header-menu-height: 40px;\n\n$btn-border-radius: 4px;\n$btn-border-radius-big: 10px;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
