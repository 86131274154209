import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonSize } from "../../../Common/Components/ToggleButton/ToggleButton";

import '../styles.scss';
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserProfile } from "../../../services/userProfile";
import cn from "classnames";
import checkIcon from '../icons/ph-check.svg'
import eyeIcon from '../icons/eye.svg'
import eyeCloseIcon from '../icons/eye-close.svg'
import { useDispatch } from "react-redux";
import { showModalPopup } from "../../../Store/action-creators";
import { PopupTitle } from "../../../Store/models";

export enum inputType {
    password = 'password',
    email = 'email',
    text = 'text'
}

export const ResetPassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const mail = searchParams.get('email') || '';

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState<boolean | null>(null);
    const [passwordType, setPasswordType] = useState<inputType>(inputType.password);

    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmValid, setPasswordConfirmValid] = useState<boolean | null>(null);
    const [passwordConfirmType, setPasswordConfirmType] = useState<inputType>(inputType.password);

    const passwordCasePattern = /(?=.*[a-z])(?=.*[A-Z])/;
    const passwordSymbolPattern = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passwordNumberPattern = /\d/;

    const handlePasswordValidation = (value: string) => {
        const lengthValid = value?.length >= 6;
        const caseValid = passwordCasePattern.test(value);
        const symbolValid = passwordSymbolPattern.test(value);
        const numberValid = passwordNumberPattern.test(value);

        setPasswordValid(lengthValid && caseValid && symbolValid && numberValid);
    }

    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        handlePasswordValidation(value);
        setPassword(value);
    }

    const handlePasswordConfirmChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        setPasswordConfirmValid(value === password);
        setPasswordConfirm(value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handlePasswordValidation(password);
        setPasswordConfirmValid(password === passwordConfirm);

        if (!password || !passwordConfirm) {
            return;
        }

        setIsLoading(true);

        UserProfile.changePassword(mail, password, passwordConfirm)
            .then((response) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.notification,
                    message: response.data.message
                }));
            })
            .catch((error: any) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.error,
                    message: error.message
                }));
            });
    };

    return (
        <div id="authPage">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-fields">
                        <div className="page-title">Reset Password</div>
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.create-new-password')}</span>
                            <input
                                type={passwordType}
                                disabled={isLoading}
                                className={"password-input "
                                    + (passwordValid === false ? 'error' : '')
                                    + (passwordValid ? 'valid' : '')}
                                onChange={handlePasswordChange} />
                            {passwordType == inputType.password &&
                                <img className="input-icon" src={eyeIcon} onClick={() => { setPasswordType(inputType.text) }} />
                            }
                            {passwordType == inputType.text &&
                                <img className="input-icon" src={eyeCloseIcon} onClick={() => { setPasswordType(inputType.password) }} />
                            }
                        </div>
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.confirm-your-password')}</span>
                            <input
                                type={passwordConfirmType}
                                disabled={isLoading}
                                className={"password-confirm-input "
                                    + (passwordConfirmValid === false ? 'error' : '')
                                    + (passwordValid && passwordConfirmValid ? 'valid' : '')}
                                onChange={handlePasswordConfirmChange} />
                            {passwordConfirmType == inputType.password &&
                                <img className="input-icon" src={eyeIcon} onClick={() => { setPasswordConfirmType(inputType.text) }} />
                            }
                            {passwordConfirmType == inputType.text &&
                                <img className="input-icon" src={eyeCloseIcon} onClick={() => { setPasswordConfirmType(inputType.password) }} />
                            }
                            <span className="form-label">*Required fields</span>
                        </div>
                        <button disabled={isLoading} className={cn({ "button-loader": isLoading, "sign-up": true })}>
                            {!isLoading && "Continue"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

