import { Candle } from "../../CandlesClient/models/Candle";
import { CHART_TYPES_ID } from "../../Components/consts";

import { EAppActions, EFinancialActions, EInstrumentManagerActions } from "../enums";
import { AddNewCandleAction, CandnleMenuItemAction, ChangeChartSettingAction, ChartScaleAction, ChartStyle, ChartTypeFrameAction, MenuItemAction, ModalPopupAction, PopupData, SettingsValue, TAppAction, TFinancialOptionsAction, TInstrumentManagerActions } from "../models";

export const changeColorTheme = (): TAppAction => {
    return {
        type: EAppActions.CHANGE_APP_COLOR_THEME,
    }
}

export const changeStockSymbol = (stockSymbol: any): TFinancialOptionsAction => {
    return {
        type: EFinancialActions.CHANGE_TERMINAL_INSTRUMENT,
        payload: stockSymbol
    }
}

export const addNewCandles = (candles: Candle): AddNewCandleAction => {
    return {
        type: EAppActions.ADD_NEW_CANDLE,
        payload: candles
    }
}

export const selectTechAnalysMenuItem = (menuItem: string | null): MenuItemAction => {
    return {
        type: EAppActions.SELECT_MENU_ITEM,
        payload: menuItem
    }
}

export const selectChartTypeItem = (style: ChartStyle): CandnleMenuItemAction => {
    return {
        type: EAppActions.SELECT_CHART_TYPE_ITEM,
        payload: style
    }
}

export const changeChartScale = (scaleIndex: number): ChartScaleAction => {
    return {
        type: EAppActions.CHANGE_CHART_SCALE,
        payload: scaleIndex
    }
}


export const openCloseInstrumentManager = (isOpen: boolean): TInstrumentManagerActions => {
    return {
        type: EInstrumentManagerActions.OPEN_CLOSE_INSTRUMENT_MANAGER,
        payload: isOpen
    }
}

export const selectChartFrameType = (typeId: CHART_TYPES_ID): ChartTypeFrameAction => {
    return {
        type: EAppActions.SELECT_CHART_TYPE_FRAME,
        payload: typeId
    }
} 

export const showModalPopup = (popupData: PopupData | null): ModalPopupAction => {
    return {
        type: EAppActions.SHOW_MODAL_POPUP,
        payload: popupData
    }
}

export const changeWheelSetting = (setting: SettingsValue): ChangeChartSettingAction => {
    return {
        type: EAppActions.CHANGE_WHEEL_SETTING,
        payload: setting
    }
}

export const changePriceSetting = (setting: SettingsValue): ChangeChartSettingAction => {
    return {
        type: EAppActions.CHANGE_PRICE_SETTING,
        payload: setting
    }
}

export const changeClusterSetting = (setting: SettingsValue): ChangeChartSettingAction => {
    return {
        type: EAppActions.CHANGE_CLUSTER_SETTING,
        payload: setting
    }
}