export enum EAppActions {
    CHANGE_APP_COLOR_THEME,
    TOGGLE_ELEMENT_VISIBILITY,
    SELECT_MENU_ITEM,
    ADD_NEW_CANDLE,
    SELECT_CHART_TYPE_ITEM,
    CHANGE_CHART_SCALE,
    SELECT_CHART_TYPE_FRAME,
    SHOW_MODAL_POPUP,
    CHANGE_WHEEL_SETTING,
    CHANGE_PRICE_SETTING,
    CHANGE_CLUSTER_SETTING,
}

export enum EInstrumentManagerActions {
    OPEN_CLOSE_INSTRUMENT_MANAGER,
}

export enum EFinancialActions {
    CHANGE_TERMINAL_INSTRUMENT = 'CHANGE_TERMINAL_INSTRUMENT'
}
