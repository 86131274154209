import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppTheme from '../Dashboard/shared-theme/AppTheme';
import {Router} from './Router';
import './App.scss';
import {ModalPopup} from './ModalPopup/ModalPopup';

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppTheme>
         <ModalPopup/>
        <Router />
      </AppTheme>
    </BrowserRouter>
  );
}; 