import React, { useState } from 'react';
import './TradePanel.scss'
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField } from '@mui/material';
import SettingsIcon from '../../../Common/Components/Icons/Settings.svg';
import AdvancedPanelIcon from '../../../Common/Components/Icons/AdvancedPanel.svg';

interface OwnProps {
    onClose: () => void;
}

export const TradePanel: React.FC<OwnProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const [balance, setBalance] = useState('999 999 999$');
    const [sellValue, setSellValue] = useState(1.00190);
    const [buyValue, setBuyValue] = useState(1.00195);
    const [settingsItems, setSettingsItems] = useState([1, 2, 3, 5, 10]);
    const [totalVolume, setTotalVolume] = useState(25);
    const [pnl, setPnl] = useState('+404,29');
    const [avgPrice, setAvgPrice] = useState('1.00250$');
    const [ratio, setRatio] = useState(1.76);

    const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {

    }


    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {

    }

    return (
        <div id="tradePanel" className="trade-panel">
            <div className='top-bar'>
                <label>{t('Components:Components.TradingPanel.Caption')}</label>
                <span className='close'>x</span>
            </div>
            <div className='content'>
                <div className='row'>
                    <label className='account'>{t('Components:Components.TradingPanel.Account')}</label>
                    <TextField select
                        onChange={handleAccountChange}>
                        <MenuItem className="menu-option" value='NASDAQ: futures'>NASDAQ: futures</MenuItem>
                    </TextField>
                </div>
                <div className='row'>
                    <label>{t('Components:Components.TradingPanel.Balance')}:</label>
                    <label className='balance-value'>{balance}</label>
                </div>
                <div className='row volume box'>
                    <label>{t('Components:Components.TradingPanel.TradeVolume')}</label>
                    <div className='volume-value'>
                        <button className='minus'>-</button>
                        <TextField select
                            onChange={handleVolumeChange}>
                            <MenuItem className="menu-option" value='NASDAQ: futures'>NASDAQ: futures</MenuItem>
                        </TextField>
                        <button className='plus'>+</button>
                    </div>
                    <div className='settings'>
                        {settingsItems.map((item, index) => {
                            return (
                                <span key={index} className="setting-value">{item}</span>
                            )
                        })}
                        <span className='setting-value'>
                            <img src={SettingsIcon} alt="settings" />
                        </span>
                    </div>
                </div>
                <div className='row toggle-container box'>
                    <span className='toggler left selected'>{t('Components:Components.TradingPanel.GTC')}</span>
                    <span className='toggler right'>{t('Components:Components.TradingPanel.Day')}</span>
                    <span className='toggler oco'>{t('Components:Components.TradingPanel.OCO')}</span>
                </div>
                <div className='row trade-container box buy'>
                    <div className='container'>
                        <div className='main-trade'>
                            <span className='title'>{t('Components:Components.TradingPanel.BuyMarket')}</span>
                            <span className='value'>{buyValue}</span>
                        </div>
                        <div className='ask'>{t('Components:Components.TradingPanel.ASK')}</div>
                        <div className='bid'>{t('Components:Components.TradingPanel.BID')}</div>
                    </div>
                </div>
                <div className='row avg-data box'>
                    <div className='avg-container'>
                        <div className='data'>
                            <span className='title'>{t('Components:Components.TradingPanel.TotalVolume')}</span>
                            <span className='value success'>{totalVolume}</span>
                        </div>
                        <div className='data'>
                            <span className='title'>{t('Components:Components.TradingPanel.PnL')}</span>
                            <span className='value success'>{pnl}</span>
                        </div>
                    </div>
                    <div className='avg-container'>
                        <div className='data'>
                            <span className='title'>{t('Components:Components.TradingPanel.AvgPrice')}</span>
                            <span className='value'>{avgPrice}</span>
                        </div>
                        <div className='data'>
                            <span className='title'>{t('Components:Components.TradingPanel.Ratio')}</span>
                            <span className='value ratio'>{ratio}</span>
                        </div>
                    </div>
                </div>
                <div className='row trade-container box sell'>
                    <div className='container'>
                        <div className='main-trade'>
                            <span className='title'>{t('Components:Components.TradingPanel.SellMarket')}</span>
                            <span className='value'>{sellValue}</span>
                        </div>
                        <div className='ask'>{t('Components:Components.TradingPanel.ASK')}</div>
                        <div className='bid'>{t('Components:Components.TradingPanel.BID')}</div>
                    </div>
                </div>
                <div className='row box controls'>
                    <button>{t('Components:Components.TradingPanel.CancellAllOrders')}</button>
                    <button>{t('Components:Components.TradingPanel.CloseAll')}</button>
                </div>
                <div className='row'>
                    <button className='advanced-btn'><img src={AdvancedPanelIcon} alt="settings" />{t('Components:Components.TradingPanel.OpenAdvancedPanel')}</button>
                </div>
            </div>
        </div>
    );
}
