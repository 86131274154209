// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.logo-container .app-title {
  width: 65px;
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/Logo/Logo.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AAFJ;AAII;EACI,WAAA;AAFR","sourcesContent":["@import 'src/Common/Styles/colors.scss';\n@import 'src/Common/Styles/metrics.scss';\n\n.logo-container {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 10px;\n\n    .app-title {\n        width: 65px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
