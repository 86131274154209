import { ApiService, ApiCandle, ApiCandleInterval, ApiCandleRequest } from "../../../../services/api"
import { Exchange, Symbol } from "../entities";
import { TPriceBar } from "./binanceRectClient";

export class DataProvider {
    public static ParseCandles = (candles: ApiCandle[]): TPriceBar[] => {
        if (candles === null) {
            return [];
        }

        const priceBars: TPriceBar[] = [];

        candles.forEach((candle: ApiCandle) => {
            const { time, open, high, low, close, volume } = candle;

            priceBars.push({
                date: time || 0 / 1000,
                open: open || 0,
                high: high || 0,
                low: low || 0,
                close: close || 0,
                volume: volume || 0,
            });
        });

        return priceBars;
    };

    public static GetCandles = async (
        dateFrom: number,
        dateTo: number,
        exchange: Exchange,
        symbol: Symbol,
        interval: ApiCandleInterval
    ): Promise<TPriceBar[]> => {
        let apiService = new ApiService(
            // 'http://127.0.0.1:8080'
        )

        let request = {
            'exchange': exchange,
            'symbol': symbol,
            'interval': interval,
            'limit': 10000000,
            'startTime': dateFrom,
            'endTime': dateTo,
        } as ApiCandleRequest;

        try {
            const data = await apiService.getCandles(request);
            return DataProvider.ParseCandles(data);
        } catch (err) {
            console.error(err);
            return [];
        }
    };
}