import { PrimeReactProvider } from 'primereact/api';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

import { createRoutes } from "../../Routes/Routes";
import { NavigationMenu } from "../NavigationMenu/NavigationMenu";
import { HeaderMenu } from "../HeaderMenu/HeaderMenu";
import { ChartsMenu } from '../ChartsMenu/ChartsMenu';

import './App.scss';
import { ModalPopup } from '../../Pages/ModalPopup';

function App() {

    return (
        <PrimeReactProvider>
            <div className="app">
                <ModalPopup />
                <NavigationMenu />
                <HeaderMenu />
                <div className='app-container'>
                    {createRoutes()}
                </div>
                <ChartsMenu />
            </div>
        </PrimeReactProvider>
    );
}

export default App;
