import React, { useState } from 'react'

import { CHART_TYPES, CHART_TYPES_ID } from '../consts'
import { ChartGroup } from "./ChartGroup/ChartGroup";
import {ChangeEventHandler} from "react/index";
import { useDispatch } from "react-redux";

import "./ChartMenu.scss";
import { selectChartFrameType } from '../../Store/action-creators';

/**
 * Меню выбора вида и параметров графика.
 *
 * @prop .
 */
interface IProps {

}

export interface Type {
    id: string;
    value: string;
    title: string;
    hideAdditinalSelector?: boolean;
    hideAdditinalPanel?: boolean;
}


export interface AdditionalValue {
    id: string;
    value: string;
    title: string;
    category: string;
}

const types = [
    { id: "0", value: "time", title: "Time" },
    { id: "1", value: "range", title: "Range" },
    { id: "2", value: "volume", title: "Volume", hideAdditinalSelector: true },
    { id: "3", value: "trades", title: "Trades" },
    { id: "4", value: "tick", title: "Tick", hideAdditinalPanel: true }
]

const additionalValues = [
    { id: "0", value: "second", title: "Second", category: "time" },
    { id: "1", value: "minutes", title: "Minutes", category: "time" },
    { id: "2", value: "hours", title: "Hours", category: "time" },
    { id: "3", value: "days", title: "Days", category: "time" },
    { id: "4", value: "month", title: "Month", category: "time" },

    { id: "5", value: "classic", title: "Classic", category: "range" },
    { id: "6", value: "modern1", title: "Modern 1", category: "range" },
    { id: "7", value: "modern2", title: "Modern 2", category: "range" },
    { id: "8", value: "modern3", title: "Modern 3", category: "range" },
    { id: "9", value: "renko", title: "Renko", category: "range" },
    { id: "10", value: "reversal", title: "Reversal", category: "range" },
    { id: "11", value: "reversalDelta", title: "Reversal Delta", category: "range" },

    { id: "12", value: "volume", title: "Volume", category: "volume" },
    { id: "13", value: "delta", title: "Delta", category: "volume" },

    { id: "14", value: "quant", title: "Quant", category: "trades" },
    { id: "15", value: "aggregate", title: "Aggregate", category: "trades" },
    { id: "16", value: "recusntruction", title: "Recusntruction", category: "trades" },
    { id: "17", value: "cluster", title: "Cluster", category: "trades" },

    { id: "18", value: "tick", title: "Tick", category: "tick" },
]


export const ChartMenu: React.FC<IProps> = ({ }: IProps) => {
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState<Type>(types[0]);
    const [selectedAdditionalValue, setSelectedAdditionalValue] = useState<AdditionalValue>(additionalValues[0]);
    const [availableAdditionalValues, setAvailableAdditionalValues] = useState<AdditionalValue[]>(additionalValues.filter(v => v.category ===selectedType.value));
    const onChangeType: ChangeEventHandler = (event): void => {

        const type = types.find(t => t.id == (event.target as HTMLInputElement).value);

        if (type != null) {
            setSelectedType(type);
            setAvailableAdditionalValues(additionalValues.filter(v => v.category === type.value));
        }
    }

    const onChangeValue: ChangeEventHandler = (event) => {
        const value = additionalValues.find(t => t.id == (event.target as HTMLInputElement).value);

        if (value != null) {
            setSelectedAdditionalValue(value);
        }
    }

    const chartItemSelect = (item: any): void => {
        const id = item._id as CHART_TYPES_ID;

        if(CHART_TYPES_ID[id] == null) {
            console.error('item is not handled: ', item)
            return;
        }
        
        dispatch(selectChartFrameType(item._id))
    } 

    return (
        <div className="chart-menu-container">
            {CHART_TYPES.map(item => {
                return <ChartGroup chartTypeData={item} selectItem={chartItemSelect}/>
            })}
{/* 
            <div className='chart-group'>
                <div className='caption'>Setting frame {selectedType.title}</div>
                <div className='selector'>Select type:
                     <select id="typeSelector" onChange={onChangeType} value={selectedType.id}>
                        {types.map((type, index) => {
                            return (
                                <option key={type.id} className="type-option" value={type.id}>{type.title}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='selector'>Value:
                    <input className='value-input'></input>
                   {!selectedType.hideAdditinalSelector && <select id="additionalValueSelector" onChange={onChangeValue} value={selectedAdditionalValue.id}>
                        {availableAdditionalValues.map((value, index) => {
                            return (
                                <option key={value.id} className="type-option" value={value.id}>{value.title}</option>
                            )
                        })}
                    </select>}
                    </div>
                <button className='apply'>Apply</button>
            </div> */}
        </div>
    )
}

ChartMenu.displayName = 'ChartMenu'
