import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonSize } from "../../../Common/Components/ToggleButton/ToggleButton";

import '../styles.scss';
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../../../services/userProfile";
import cn from "classnames";
import checkIcon from '../icons/ph-check.svg'
import eyeIcon from '../icons/eye.svg'
import eyeCloseIcon from '../icons/eye-close.svg'
import { useDispatch } from "react-redux";
import { logIn, showModalPopup } from "../../../Store/action-creators";
import { PopupTitle } from "../../../Store/models";
import { forgotPasswordPath, loginPath, registrationPath } from "../../Navigation";

export enum inputType {
    password = 'password',
    email = 'email',
    text = 'text'
}

export const Login: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false)

    const [email, setEmail] = useState('');
    const [emailValid, setEmaiValid] = useState<boolean | null>(null);

    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState<boolean | null>(null);
    const [passwordType, setPasswordType] = useState<inputType>(inputType.password);

    const [passwordLengthValid, setPasswordLengthValid] = useState<boolean | null>(null);
    const [passwordCaseValid, setPasswordCaseValid] = useState<boolean | null>(null);
    const [passwordSymbolValid, setPasswordSymbolValid] = useState<boolean | null>(null);


    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{6,}$/;

    const passwordCasePattern = /(?=.*[a-z])(?=.*[A-Z])/;
    const passwordSymbolPattern = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passwordNumberPattern = /\d/;

    const handleMailVaidation = (mail: string) => {
        setEmaiValid(emailPattern.test(mail));
    }

    const handlePasswordValidation = (value: string) => {
        const lengthValid = value?.length >= 6;
        const caseValid = passwordCasePattern.test(value);
        const symbolValid = passwordSymbolPattern.test(value);
        const numberValid = passwordNumberPattern.test(value);

        setPasswordValid(lengthValid && caseValid && symbolValid && numberValid);
        setPasswordLengthValid(lengthValid);
        setPasswordCaseValid(caseValid);
        setPasswordSymbolValid(symbolValid && numberValid);
    }

    const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        handleMailVaidation(value);
        setEmail(value);
    }

    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        handlePasswordValidation(value);
        setPassword(value);
    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handleMailVaidation(email);
        handlePasswordValidation(password);

        if (!emailValid || !passwordValid) {
            return;
        }

        setIsLoading(true);

        UserProfile.signIn(email, password)
            .then((response) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.notification,
                    message: response.data.message
                }));

                dispatch(logIn({ profile: response.data.hash }));

                navigate('/');
            })
            .catch((error: any) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.error,
                    message: error.message
                }));

                console.log(error)
            });
    };


    const toggleButtons = [
        {
            id: '1',
            label: t('UserManagement:signIn.title'),
            path: loginPath
        },
        {
            id: '2',
            label: t('UserManagement:signUp.title'),
            path: registrationPath
        }
    ]

    const currentTab = toggleButtons[0];

    const handleTabSelect = (tab: { id: string, label: string, path?: string }) => {
        if (tab.id === currentTab.id || tab.path == null) {
            return;
        }
        console.log(tab);

        navigate(tab.path);
    }

    return (
        <div id="authPage">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <ToggleButton value={toggleButtons[0]} items={toggleButtons} onClick={handleTabSelect} size={ToggleButtonSize.Large} />
                    <div className="form-fields">
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.enter-e-mail')}</span>
                            <input
                                type="email"
                                disabled={isLoading}
                                className={"email-input "
                                    + (emailValid === false ? 'error' : '')
                                    + (emailValid ? 'valid' : '')}
                                onChange={handleEmailChange} />
                            {emailValid &&
                                <img className="input-icon" src={checkIcon} />
                            }
                        </div>
                        <div className="field-container">
                            <span className="form-label description">Enter your password*</span>
                            <input
                                type={passwordType}
                                disabled={isLoading}
                                className={"password-input "
                                    + (passwordValid === false ? 'error' : '')
                                    + (passwordValid ? 'valid' : '')}
                                onChange={handlePasswordChange} />
                            {passwordType == inputType.password &&
                                <img className="input-icon" src={eyeIcon} onClick={() => { setPasswordType(inputType.text) }} />
                            }
                            {passwordType == inputType.text &&
                                <img className="input-icon" src={eyeCloseIcon} onClick={() => { setPasswordType(inputType.password) }} />
                            }
                        </div>
                        <div className="forgot-password" onClick={() => { navigate(forgotPasswordPath) }}>Forgot your password ?</div>
                        <button disabled={isLoading} className={cn({ "button-loader": isLoading, "sign-up": true })}>
                            {!isLoading && "Sign In"}
                        </button>
                        <div className="field-container row">
                            <span className="form-label">No account ? </span>
                            <span className="sign-in" onClick={() => { navigate(registrationPath) }}>Sign up</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

