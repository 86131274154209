import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonSize } from "../../../Common/Components/ToggleButton/ToggleButton";
import '../styles.scss';
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../../../services/userProfile";
import cn from "classnames";
import checkIcon from '../icons/ph-check.svg'
import eyeIcon from '../icons/eye.svg'
import eyeCloseIcon from '../icons/eye-close.svg'
import { useDispatch } from "react-redux";
import { logIn, showModalPopup } from "../../../Store/action-creators";
import { PopupTitle } from "../../../Store/models";
import { loginPath, registrationPath } from "../../Navigation";

export enum InputType {
    password = 'password',
    email = 'email',
    text = 'text'
}

export const Registration: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('');
    const [emailValid, setEmaiValid] = useState<boolean | null>(null);

    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState<boolean | null>(null);
    const [passwordType, setPasswordType] = useState<InputType>(InputType.password);

    const [passwordLengthValid, setPasswordLengthValid] = useState<boolean | null>(null);
    const [passwordCaseValid, setPasswordCaseValid] = useState<boolean | null>(null);
    const [passwordSymbolValid, setPasswordSymbolValid] = useState<boolean | null>(null);

    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmValid, setPasswordConfirmValid] = useState<boolean | null>(null);
    const [passwordConfirmType, setPasswordConfirmType] = useState<InputType>(InputType.password);

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const passwordCasePattern = /(?=.*[a-z])(?=.*[A-Z])/;
    const passwordSymbolPattern = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passwordNumberPattern = /\d/;
    const toggleButtons = [
        {id: '1', label: t('UserManagement:signIn.title'), path: loginPath},
        {id: '2', label: t('UserManagement:signUp.title'), path: registrationPath}
    ]

    const currentTab = toggleButtons[1];

    const handleTabSelect = (tab: { id: string, label: string, path?: string }) => {
        if (tab.id === currentTab.id || tab.path == null) {
            return;
        }

        navigate(tab.path);
    }

    const handleMailVaidation = (mail: string) => {
        setEmaiValid(emailPattern.test(mail));
    }

    const handlePasswordValidation = (value: string) => {
        const lengthValid = value?.length >= 6;
        const caseValid = passwordCasePattern.test(value);
        const symbolValid = passwordSymbolPattern.test(value);
        const numberValid = passwordNumberPattern.test(value);

        setPasswordValid(lengthValid && caseValid && symbolValid && numberValid);
        setPasswordLengthValid(lengthValid);
        setPasswordCaseValid(caseValid);
        setPasswordSymbolValid(symbolValid && numberValid);
    }

    const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        handleMailVaidation(value);
        setEmail(value);
    }

    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        handlePasswordValidation(value);
        setPassword(value);
    }

    const handlePasswordConfirmChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        setPasswordConfirmValid(value === password);
        setPasswordConfirm(value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handlePasswordValidation(password);
        handleMailVaidation(email);
        setPasswordConfirmValid(password === passwordConfirm);

        if (!emailValid || !passwordValid || !passwordConfirmValid) {
            return;
        }

        setIsLoading(true)

        UserProfile.signUp(email, password)
            .then((response) => {
                setIsLoading(false);

                dispatch(showModalPopup({
                    title: PopupTitle.notification,
                    message: response.data.message
                }));

                dispatch(logIn({ hash: response.data.hash }))
            })
            .catch((error: any) => {
                setIsLoading(false)

                dispatch(showModalPopup({
                    title: PopupTitle.error,
                    message: error.message
                }));

                console.log(error)
            });
    };

    return (
        <div id="authPage">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <ToggleButton value={currentTab} items={toggleButtons} onClick={handleTabSelect} size={ToggleButtonSize.Large} />
                    <div className="form-fields">
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.enter-e-mail')}</span>
                            <input
                                type="email"
                                disabled={isLoading}
                                className={"email-input "
                                    + (emailValid === false ? 'error' : '')
                                    + (emailValid ? 'valid' : '')}
                                onChange={handleEmailChange} />
                            {emailValid &&
                                <img className="input-icon" src={checkIcon} />
                            }
                        </div>
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.create-new-password')}</span>
                            <input
                                type={passwordType}
                                disabled={isLoading}
                                className={"password-input "
                                    + (passwordValid === false ? 'error' : '')
                                    + (passwordValid ? 'valid' : '')}
                                onChange={handlePasswordChange} />
                            {passwordType == InputType.password &&
                                <img className="input-icon" src={eyeIcon} onClick={() => { setPasswordType(InputType.text) }} />
                            }
                            {passwordType == InputType.text &&
                                <img className="input-icon" src={eyeCloseIcon} onClick={() => { setPasswordType(InputType.password) }} />
                            }
                            <span className={"form-label rule "
                                + (passwordLengthValid === false ? 'error' : '')
                                + (passwordLengthValid ? 'valid' : '')}>
                                should be at least 6 characters long
                            </span>
                            <span className={"form-label rule "
                                + (passwordCaseValid === false ? 'error' : '')
                                + (passwordCaseValid ? 'valid' : '')}>
                                should contain both upper and lower-case characters
                            </span>
                            <span className={"form-label rule "
                                + (passwordSymbolValid === false ? 'error' : '')
                                + (passwordSymbolValid ? 'valid' : '')}>
                                should contain punctuation characters and digits
                            </span>
                        </div>
                        <div className="field-container">
                            <span className="form-label description">{t('UserManagement:signUp.confirm-your-password')}</span>
                            <input
                                type={passwordConfirmType}
                                disabled={isLoading}
                                className={"password-confirm-input "
                                    + (passwordConfirmValid === false ? 'error' : '')
                                    + (passwordValid && passwordConfirmValid ? 'valid' : '')}
                                onChange={handlePasswordConfirmChange} />
                            {passwordConfirmType == InputType.password &&
                                <img className="input-icon" src={eyeIcon} onClick={() => { setPasswordConfirmType(InputType.text) }} />
                            }
                            {passwordConfirmType == InputType.text &&
                                <img className="input-icon" src={eyeCloseIcon} onClick={() => { setPasswordConfirmType(InputType.password) }} />
                            }
                            <span className="form-label">*Required fields</span>
                        </div>
                        <button disabled={isLoading} className={cn({ "button-loader": isLoading, "sign-up": true })} type="submit">
                            {!isLoading && "Sign Up"}
                        </button>
                        <div className="field-container row">
                            <span className="form-label">Already have an account ? </span>
                            <span className="sign-in" onClick={() => { navigate(loginPath) }}>Sign in</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Registration;