import React from "react";
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Divider,

} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";

export class SettingsPage extends React.Component {

    state = {
        theme: 'light',
        connector: 'local',
        language: 'en',
        emailNotifications: true,
        betaTesting: false,
        currency: 'USD',
    };


    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const target = e.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
        const {name, value} = target;
        const checked = (target as HTMLInputElement).checked;
        const type = target.type;

        this.setState({
            [name as string]: type === 'checkbox' ? checked : value
        });
    };
    handleSave = () => {
        // Add your save logic here
        console.log('Settings saved:', this.state);
    };

    onSelectAbort = (event: React.SyntheticEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    render() {
        return (
            <Container>
                <Typography variant="h5" gutterBottom>
                    Settings
                </Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Theme</InputLabel>
                    <Select name="theme" value={this.state.theme}
                            onChange={(event: SelectChangeEvent<string>, child: React.ReactNode) => {
                                this.handleChange(event as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
                            }}
                            onAbort={this.onSelectAbort}>
                        <MenuItem value="light">Light</MenuItem>
                        <MenuItem value="dark">Dark</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Connector</InputLabel>
                    <Select
                        name="connector"
                        value={this.state.connector}
                        onChange={(event: SelectChangeEvent<string>, child: React.ReactNode) => {
                            this.handleChange(event as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
                        }}
                    >
                        <MenuItem value="local">Local</MenuItem>
                        <MenuItem value="binance">Binance</MenuItem>
                    </Select>
                </FormControl>


                    <ListItemText
                        primary="Display Currency"
                        secondary="Choose your preferred currency"
                    />

                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.emailNotifications}
                            onChange={(event: SelectChangeEvent<string>, child: React.ReactNode) => {
                                this.handleChange(event as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
                            }}
                            name="emailNotifications"
                        />
                    }
                    label="Email Notifications"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.betaTesting}
                            onChange={(event: SelectChangeEvent<string>, child: React.ReactNode) => {
                                this.handleChange(event as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
                            }}
                            name="betaTesting"
                        />
                    }
                    label="Beta Testing"
                />
                {/* Display button on the right */}
                <Button variant="contained" classes={{root: 'button-right'}}
                        color="primary"
                        onClick={this.handleSave}

                >
                    Save
                </Button>
            </Container>
        );
    }
}