import { MenuItem, TextField } from "@mui/material";
import { Indicator } from "../../../CandlesClient/models/Candle";
import EyeIcon from '../../../Common/Components/Icons/Eye.svg';
import PinIcon from '../../../Common/Components/Icons/Pin.svg';
import "./IndicatorsMenu.scss";

export interface IndicatorsMenuProps {
    indicators: Indicator[];
    updateIndicators: Function;
}

export const IndicatorsMenu = (props: IndicatorsMenuProps) => {
    const { indicators, updateIndicators } = props;
    const count = indicators?.length || 0;

    const pinIndicator = (event: any, indicator: Indicator) => {
        event.stopPropagation();

        const nextIndicators = indicators.map(i => {
            if (i.id !== indicator.id) {
                return i;
            } else {
                return {
                    ...i,
                    pin: !i.pin,
                };
            }
        });

        updateIndicators(nextIndicators);
    }

    const turnIndicator = (event: any, indicator: Indicator) => {
        event.stopPropagation();

        const nextIndicators = indicators.map(i => {
            if (i.id !== indicator.id) {
                return i;
            } else {
                return {
                    ...i,
                    turned: !i.turned,
                };
            }
        });

        updateIndicators(nextIndicators);
    }

    return (
        <div id="indicatorMenuContainer">
            <div className="indicator-list">
                {indicators.filter(i => i.pin).map((indicator) => {
                    return (
                        <div className="pinned-indicator" key={indicator.id}>
                            <img className={"eye-icon " + (indicator.turned ? 'active' : '')}
                                src={EyeIcon}
                                onClick={($event) => {
                                    turnIndicator($event, indicator)
                                }} />
                            <span className="indicator-name">{indicator.name}</span>
                        </div>
                    )
                })}
                <MenuItem>
                    <div className="indicator-container">
                        <TextField select value={0}>
                            <MenuItem className="hidden-caption" value={0}>
                                <span className="indicators-counter">{count}</span>
                                <span className="indicator-menu-caption">{'Indicators'}</span>
                            </MenuItem>
                            {indicators.map((indicator) => {
                                return (
                                    <MenuItem className="menu-option" key={indicator.id} value={indicator.id}>
                                        <img className={"eye-icon " + (indicator.turned ? 'active' : '')}
                                            src={EyeIcon}
                                            onClick={($event) => {
                                                turnIndicator($event, indicator)
                                            }} />
                                        <img className={"pin-icon " + (indicator.pin ? 'active' : '')}
                                            src={PinIcon}
                                            onClick={($event) => {
                                                pinIndicator($event, indicator)
                                            }} />
                                        <span className="indicator-menu-name">{indicator.name}</span>
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </div>
                </MenuItem>
            </div>
        </div>
    )
}
