import React from 'react';
// import './index.css';
import './i18n/config'
import ReactDOM from 'react-dom';
// import App from './Components/App/App';
import {App} from './Pages/App';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {rootReducer} from "./Store/reducers";
import { Provider } from 'react-redux';

const composeEnhancers = compose;

export const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );