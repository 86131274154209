/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { Moment } from "moment";
import { ChartEvent, TimeFrame } from "../Modules/Candles/constants";
import { ChartStyle, SettingsValue } from "../Store/models";

export type Candle = {
    isNew?: boolean;
    empty?: boolean;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
    ask?: number;
    bid?: number;
    tradesCount?: number;
    priceClusters?: Record<string, {
        ask?: number;
        bid?: number;
        durationMilli?: number;
        trades?: number;
    }>;
    askBid?: AskBid;
    deltaLevels?: Levels;
    volumeLevels?: Levels;
    priceSnake?: Array<number>;
};

export type Cluster = {
    high: number;
    low: number;
    open: number;
    close: number;
    AskBid: { Ask: number; Bid: number; };
    deltaLevels?: Levels;
    volumeLevels?: Levels;
};
export type Levels = {
    minDeltaValue?: number;
    minDeltaPrice?: number;
    maxDeltaValue?: number;
    maxDeltaPrice?: number;
}

export type AskBid = {
    ask?: number;
    bid?: number;
}

export enum ChartType {
    lineChart = 'lineChart',
    candleChart = 'candleChart',
    clusterChart = 'clusterChart'
}

export interface FrameCount {
    timeCount: number;
    timeFrame: TimeFrame;
    scaleIndex: number;
}

export enum ClusterViewType {
    default,
    single,
    multi
}

export interface UCandle extends Candle {
    //japaneese candle properties
    id: number;
    timeClose: number;
    lowY?: number;
    highY?: number;
    openY?: number;
    closeY?: number;
    isGoingUp?: boolean;
    direction?: CandleDirection;
    candleHeight?: number;
    bottomTailY?: number;
    bottomTailHeight?: number;
    topTailY?: number;
    topTailHeight?: number;
    candleY?: number;
    bodyyX?: number;
    candleX?: number;
    openConnectorX?: number;
    closeConnectorX?: number;
    snakeHeight?: number;
    snakeY?: number;
    snakeX?: number;
    clusterData?: any[];

    //indicator properties
    indicatorHeight?: number;
    indicatorY?: number;
    indicatorX?: number;
    indicatorWidth?: number;
    indicatorPercents?: number;

    //cluster properties
    clusterElements?: UClusterElement[];
    wrapperPath?: string;
}

export interface newCandlesData {
    newCandles: UCandle[];
    newSpaceGap: number;
}

export enum CandleDirection {
    goingUp = 'goingUp',
    goingDown = 'goingDown'
}

export interface Indicator {
    id: number;
    pin: boolean;
    name: IndicatorName;
    turned: boolean;
    points?: Point[];
    linePath?: string;
    gradientPath?: string;
}

export enum IndicatorName {
    histogram = 'histogram',
    background = 'background indicator',
    lineChart = 'line chart',
    lineGradientChart = 'line gradient chart',

}

export interface UClusterElement {
    ask: number;
    bid: number;
    AskSegments?: ClusterSegment[];
    BidSegments?: ClusterSegment[];
    Segment?: ClusterSegment;
    isGoingUp?: boolean;
    color: string;
    opacityCoef: number;
    indicatorPositionX: number;
    indicatorPositionY: number;
    counterPositionX: number;
    counterPositionY: number;
    indicatorWidth: number;
    priceValue: number;
    insideBody: boolean;
    value: string;

    // server response
    price_level?: number;
    bid_volume?: number;
    ask_volume?: number;
}

export interface ClusterSegment {
    width: number;
    opacity: number;
    positionX: number;
    color: string;
}

export interface PriceValue {
    positionY: number;
    value: number;
}

export interface TimeValue {
    positionX: number;
    value: string;
}

export interface CandleTileResult {
    tiles: UCandle[][];
    lowest: number;
    highest: number;
}

export interface Point {
    x: number;
    y: number;
}

export interface LineChartData {
    points?: Point[];
    linePath?: string;
    gradientPath?: string;
    upGradientPath?: string;
    downGradientPath?: string;
    upLinePath?: string;
    downLinePath?: string;
}

export interface Extremums extends CandlesExtremums {
    lowestPoint: number;
    highestPoint: number;
    scrollTop: number;
}

export interface CandlesExtremums {
    lowestPrice: number;
    highestPrice: number;
    highestVolume: number;
}

export interface Scroll {
    top: number,
    left: number,
    x: number,
    y: number
}

export interface CandleSize {
    candleWidth: number;
    tailWidth: number;
    snakeWidth: number;
    connectorWidth: number;
    clusterHeight: number;
    clusterSize: number;
}

export interface InitCandlesPayload {
    type: ChartEvent.InitCandlesData;
    rows: any[];
    scaleIndex: number;
    canvasWidth: number;
    canvasHeight: number;
    chartStyle: ChartStyle;
}

export interface InitCandlesSuccessPayload {
    type: ChartEvent.InitCandlesDataSuccess;
    candles: UCandle[];
    priceValues: PriceValue[];
    timeValues: TimeValue[];
    extremums: Extremums;
    spaceGap: number;
    scrollLeft: number;
    candleSize: CandleSize;
    lineChartData?: LineChartData;
}

export interface AddNewCandlesPayload {
    type: ChartEvent.AddNewCandles;
    rows: any[];
    candles: UCandle[];
    scaleIndex: number;
    spaceGap: number;
    canvasHeight: number;
    chartStyle: ChartStyle;
    extremums: Extremums;
    direction?: boolean;
}

export interface AddNewCandlesSuccessPayload {
    type: ChartEvent.AddNewCandlesSuccess;
    candles: UCandle[];
    lineChartData?: LineChartData;
    spaceGap: number;
    extremums: Extremums;
    timeValues: TimeValue[];
    candleSize: CandleSize;
}

export interface ScalePayload {
    type: ChartEvent.HandleChartScale;
    candles: UCandle[];
    scaleIndex: number;
    extremums: Extremums;
    canvasWidth: number;
    spaceGap: number;
    dif: number;
    chartStyle: ChartStyle;
}

export interface ScaleSuccessPayload {
    type: ChartEvent.HandleChartScaleSuccess;
    candles: UCandle[];
    scaleIndex: number;
    spaceGap: number;
    timeValues: TimeValue[];
    lineChartData?: LineChartData;
    chartType: ChartType;
    candleSize: CandleSize;
}

export interface ChangeModePayload {
    type: ChartEvent.HandleModeChange;
    candles: UCandle[];
    spaceGap: number;
    scaleIndex: number;
    canvasHeight: number;
    canvasWidth: number;
    scrollLeft: number;
    staticModeActive: boolean;
    chartStyle: ChartStyle;
}

export interface ChangeModeSuccessPayload {
    type: ChartEvent.HandleModeChangeSuccess;
    candles: UCandle[];
    extremums: Extremums;
    priceValues: PriceValue[];
    timeValues: TimeValue[];
    staticModeActive: boolean;
    lineChartData?: LineChartData;
    candleSize: CandleSize;
}


export interface NewExtremumsPayload {
    type: ChartEvent.HandleNewExtremums;
    candles: UCandle[];
    spaceGap: number;
    scaleIndex: number;
    canvasWidth: number;
    scrollLeft: number;
    chartStyle: ChartStyle;
    canvasHeight: number;
}

export interface NewExtremumsSuccessPayload {
    type: ChartEvent.HandleNewExtremumsSuccess;
    candles: UCandle[];
    extremums: Extremums;
    priceValues: PriceValue[];
    timeValues: TimeValue[];
    lineChartData?: LineChartData;
    candleSize: CandleSize;
}

export interface ChangeZoomPayload {
    type: ChartEvent.HandleChartZoom;
    candles: UCandle[];
    scaleIndex: number;
    extremums: Extremums;
    canvasHeight: number;
    direction: boolean;
    spaceGap: number;
    chartStyle: ChartStyle;
    scaleSetting: SettingsValue;
}

export interface ChangeZoomSuccessPayload {
    type: ChartEvent.HandleChartZoomSuccess;
    candles: UCandle[];
    extremums: Extremums;
    priceValues: PriceValue[];
    timeValues: TimeValue[];
    lineChartData?: LineChartData;
    candleSize: CandleSize;
}

export interface ScrollPricePayload {
    type: ChartEvent.HandleChartZoom;
    candles: UCandle[];
    scaleIndex: number;
    extremums: Extremums;
    direction: boolean;
    staticModeActive: boolean;
    chartStyle: ChartStyle;
}

export interface UpdateClustersPayload {
    type: ChartEvent.UpdateClusters;
    rows: any[];
    scaleIndex: number;
    extremums: Extremums;
    spaceGap: number;
}

export interface UpdateClustersSuccessPayload {
    type: ChartEvent.UpdateClustersSuccess;
    candles: UCandle[];
}
