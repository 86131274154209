import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const mainListItems = [
    {text: 'Trading', icon: <AnalyticsRoundedIcon/>, link: '/trading'},
    {text: 'Statistics', icon: <AnalyticsRoundedIcon/>, link: '/statistics'},
    {text: 'Analytics', icon: <AnalyticsRoundedIcon/>, link: '/analytics'},
    {text: 'Market rePlay', icon: <AssignmentRoundedIcon/>, link: '/market-replay'},
];

const secondaryListItems = [
    {text: 'Settings', icon: <SettingsRoundedIcon/>, link: '/settings'},
    {text: 'About', icon: <InfoRoundedIcon/>, link: '/about'},
    {text: 'Feedback', icon: <HelpRoundedIcon/>, link: '/feedback'},
];

export default function MenuContent() {
    return (
        <Stack sx={{flexGrow: 1, p: 1, justifyContent: 'space-between'}}>
            <List dense>
                {mainListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: 'block'}}>
                        <ListItemButton component="a" href={item.link}
                                        selected={item.link === window.location.pathname}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <List dense>
                {secondaryListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: 'block'}}>
                        <ListItemButton component="a" href={item.link}
                                        selected={item.link === window.location.pathname}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}
