// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.MuiBox-root {
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.MuiStack-root {
  max-height: 100%;
}

.MuiContainer-root {
  max-width: 100% !important;
}

html[data-mui-color-scheme=dark] > #tradingMarkets > #root {
  background-color: #0A1F2E !important;
  --text: #333;
  --border: 0;
  --shadow: 0 ;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 10px;
  border: 3px solid #f0f0f0;
}

::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

::-webkit-scrollbar-horizontal {
  height: 12px;
}

::-webkit-scrollbar-vertical {
  width: 12px;
}

.auth-page .MuiStack-root .MuiStack-root,
.auth-page .MuiDrawer-root {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/App.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAIK;EACC,oCAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AADN;;AAMA;EACE,WAAA;EACA,YAAA;AAHF;;AAMA;EACE,mBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,mBAAA;EACA,mBAAA;EACA,yBAAA;AAHF;;AAMA;EACE,mBAAA;AAHF;;AAMA;EACE,YAAA;AAHF;;AAMA;EACE,WAAA;AAHF;;AAMA;;EAEE,wBAAA;AAHF","sourcesContent":["main.MuiBox-root {\n  width: 100%;\n  max-height: 100%;\n  overflow: hidden;\n}\n\n.MuiStack-root {\n  max-height: 100%;\n}\n\n.MuiContainer-root {\n  max-width: 100% !important;\n}\n\nhtml[data-mui-color-scheme=\"dark\"] {\n  > #tradingMarkets {\n     > #root {\n      background-color: #0A1F2E !important;\n      --text: #333;\n      --border: 0;\n      --shadow: 0 ;\n    }\n  }\n}\n\n::-webkit-scrollbar {\n  width: 12px;\n  height: 12px;\n}\n\n::-webkit-scrollbar-track {\n  background: #f0f0f0;\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #b0b0b0;\n  border-radius: 10px;\n  border: 3px solid #f0f0f0;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #8c8c8c;\n}\n\n::-webkit-scrollbar-horizontal {\n  height: 12px;\n}\n\n::-webkit-scrollbar-vertical {\n  width: 12px;\n}\n\n.auth-page .MuiStack-root .MuiStack-root,\n.auth-page .MuiDrawer-root {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
