import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import MainGrid from './components/MainGrid';
import { UserIdCookie } from '../services/constants';

/**
 * Dashboard Component
 * 
 * Main dashboard view that displays the application's primary interface.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {boolean} [props.disableCustomTheme] - Whether to disable custom theming
 * 
 * Features:
 * - Session validation with automatic redirect to login
 * - Main grid layout for dashboard content
 * - Theme customization support
 * 
 * Usage:
 * ```tsx
 * <Dashboard disableCustomTheme={false} />
 * ```
 */

export default function Dashboard(props: { disableCustomTheme?: boolean }) {
    const navigate = useNavigate();
    useEffect(() => {
        const userSessionId = Cookies.get(UserIdCookie);
        if (!userSessionId) {
            navigate('/user/login');
        }
    }, [navigate]);

    return (
        <MainGrid/>
    );
}
