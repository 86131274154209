import { useEffect, useRef, useState } from "react";
import "./CandlestickChart.scss";
import StaticModeIcon from '../../../Common/Components/Icons/StaticModeIcon.png';
import {
    AxisBase2D,
    EAutoRange,
    FastCandlestickRenderableSeries,
    FastOhlcRenderableSeries,
    NumberRange,
    OhlcDataSeries,
    SciChartSurface,
    TSciChart
} from "scichart";
import { SciChartReact, TResolvedReturnType } from "scichart-react";
import { initChart, setChartSurface, updateAnnotations } from "./drawExample";
import { appTheme } from "./theme";
import PriceScale from "./PriceScale/PriceScale";
import { ChartEvent, ChartUserEvent, DATE_FORMAT, ElementId, Exchange, Symbol, Point, Range, userConfig } from "./entities";
import TimeScale from "./TimeScale/TimeScale";
import moment from "moment";
import { Candle } from "./Chart";
import { ApiCandleInterval } from "../../../services/api";
import { generatePriceTicks, getDateByGap } from "./helper";
import { CustomTickProvider } from "./ExampleData/tickProvider";

interface CandleStickProps {
    currentCandle?: Candle;
    dateFrom: number,
    dateTo: number,
    min: number,
    max: number,
    exchange: Exchange,
    symbol: Symbol,
    interval: ApiCandleInterval
}

const CandlestickChart: React.FC<CandleStickProps> = ({ dateFrom, dateTo, min, max, currentCandle, exchange, symbol, interval }) => {
    const [candlestickDataSeries, setCandlestickDataSeries] = useState<OhlcDataSeries>();
    const [candlestickChartSeries, setCandlestickChartSeries] = useState<FastCandlestickRenderableSeries>();
    const [ohlcChartSeries, setOhlcChartSeries] = useState<FastOhlcRenderableSeries>();
    const [sciChartSurface, setSciChartSurface] = useState<SciChartSurface>();
    const [wasmContext, setWasmContext] = useState<TSciChart>();

    const chartRef = useRef<HTMLDivElement>(null);
    const priceRef = useRef<HTMLDivElement>(null);
    const timeRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const dragPosition = useRef<Point>({ x: 0, y: 0 });
    const events = useRef<ChartEvent>({ timeScaleActive: false, priceScaleActive: false, scrollingActive: false });

    const [currentChartEvent, setCurrentChartEvent] = useState<ChartUserEvent>(ChartUserEvent.None);
    const [dragDelta, setDragDelta] = useState<Point>({ x: 0, y: 0 });
    const [priceRange, setPriceRange] = useState<Range>({ min: 0, max: 0 });
    const [timeRange, setTimeRange] = useState<Range>({ min: 0, max: 0 });
    const [priceAxis, setPriceAxis] = useState<AxisBase2D | null>();
    const [timeTicks, setTimeTicks] = useState<number[]>([]);
    const [priceTicks, setPriceTicks] = useState<number[]>([]);
    const [timeAxis, setTimeAxis] = useState<AxisBase2D | null>();
    const [zoomDeltaX, setZoomDeltaX] = useState<number>(0);
    const [zoomDeltaY, setZoomDeltaY] = useState<number>(0);
    const [staticModeActive, setStaticModeActive] = useState<boolean>(false);

    SciChartSurface.useWasmFromCDN();
    const initFunc = initChart(dateFrom, dateTo, { max, min }, exchange, symbol, interval);


    const scalePriceRange = () => {
        const gap = priceRange.max - priceRange.min;

        if (gap <= userConfig.minPriceGap && zoomDeltaY < 0) {
            return;
        }

        const valueToChange = gap / 100 * userConfig.scaleStepPercents;

        const topValueToCahnge: number = zoomDeltaY > 0 ? valueToChange : valueToChange * -1;
        const bottomValueToChange: number = topValueToCahnge * -1;

        setPriceRange({ min: priceRange.min + bottomValueToChange, max: priceRange.max + topValueToCahnge });
    }

    const scaleTimeRange = (delta: number) => {
        const gap = timeRange.max - timeRange.min;

        if (gap <= userConfig.minTimeGap && delta < 0) {
            return;
        }

        const valueToChange = gap / 100 * userConfig.scaleStepPercents;

        const topValueToCahnge: number = delta > 0 ? valueToChange : valueToChange * -1;
        const bottomValueToChange: number = topValueToCahnge * -1;
        const range = { min: timeRange.min + bottomValueToChange, max: timeRange.max + topValueToCahnge };

        setTimeRange(range);
    }

    useEffect(() => {
        if (candlestickDataSeries == null || currentCandle == null || sciChartSurface == null) {
            return;
        }

        //@ts-ignore
        if (candlestickDataSeries.toJSON().options.xValues.includes(currentCandle.date)) {
            const index = candlestickDataSeries.findIndex(currentCandle.date)
            candlestickDataSeries.update(index, currentCandle.open, currentCandle.high, currentCandle.low, currentCandle.close)
        } else {
            candlestickDataSeries.append(currentCandle.date, currentCandle.open, currentCandle.high, currentCandle.low, currentCandle.close);
            console.log("CANDLE ADDED: ", currentCandle);
        }
    }, [currentCandle])

    useEffect(() => {
        //@ts-ignore
        const yAxis = sciChartSurface?.yAxes?.items.find(scale => scale.id === ElementId.PriceScale);
        //@ts-ignore
        const xAxis = sciChartSurface?.xAxes.items.find(scale => scale.id === ElementId.TimeScale);

        if (yAxis == null || xAxis == null) {
            return;
        }

        setPriceAxis(yAxis);
        setTimeAxis(xAxis);

        sciChartSurface?.getMainCanvas().addEventListener('pointermove', (event) => {
            if (!events.current.scrollingActive) {
                return;
            }

            const x = (event.clientX - dragPosition.current.x) / 1;
            const y = (event.clientY - dragPosition.current.y) / 1;
            dragPosition.current = { x: event.clientX, y: event.clientY };
            setDragDelta({ x, y });
        });

        sciChartSurface?.getMainCanvas().addEventListener('pointerdown', () => {
            events.current.scrollingActive = true;
        });

        sciChartSurface?.getMainCanvas().addEventListener('pointerup', () => {
            events.current.scrollingActive = false;
            setCurrentChartEvent(ChartUserEvent.MouseUp);
        });

        sciChartSurface?.getMainCanvas().addEventListener('pointerleave', () => {
            events.current.scrollingActive = false;
        });
    }, [sciChartSurface]);

    useEffect(() => {
        if (priceAxis == null || wasmContext == null) {
            return;
        }

        setPriceRange({ ...priceAxis.visibleRange });
        priceAxis.autoRange = EAutoRange.Never;

       // console.log(priceAxis.tickProvider.getMajorTicks(priceAxis.visibleRange.min,priceAxis.visibleRange.max,priceAxis.visibleRange));

    
    }, [priceAxis]);

    useEffect(() => {
        if (timeAxis == null) {
            return;
        }

        setTimeRange({ ...timeAxis.visibleRange });
        timeAxis.autoRange = EAutoRange.Never;
    }, [timeAxis]);

    useEffect(() => {
        if (priceRange.max === 0 && priceRange.min === 0) {
            return;
        }

        if (priceAxis == null) {
            return;
        }

        const ticks = generatePriceTicks(priceRange.min, priceRange.max);
        setPriceTicks(ticks);

        priceAxis.visibleRange = new NumberRange(priceRange.min, priceRange.max);
    }, [priceRange]);

    // useEffect(() => {
    //     if (sciChartSurface == null || priceTicks == null || priceTicks.length === 0) {
    //         return;
    //     }

    //     updateAnnotations(sciChartSurface, priceTicks);
    // }, [priceTicks])

    useEffect(() => {
        if (currentChartEvent === ChartUserEvent.None) {
            return;
        }

        setCurrentChartEvent(ChartUserEvent.None);

        updateChartSurface();
    }, [currentChartEvent]);

    useEffect(() => {
        if (zoomDeltaY === 0) {
            return;
        }

        setStaticModeActive(false);
        setZoomDeltaY(0);
        scalePriceRange();
    }, [zoomDeltaY]);


    useEffect(() => {
        if (timeAxis == null || priceAxis == null) {
            return;
        }

        setTimeRange(timeAxis.visibleRange);
        setPriceRange(priceAxis.visibleRange);
    }, [dragDelta]);

    useEffect(() => {
        if (zoomDeltaX === 0) {
            return;
        }

        scaleTimeRange(zoomDeltaX);
        setZoomDeltaX(0);
        setCurrentChartEvent(ChartUserEvent.ScaleX);
    }, [zoomDeltaX]);

    useEffect(() => {
        if (priceAxis == null) {
            return;
        }

        priceAxis.autoRange = staticModeActive ? EAutoRange.Always : EAutoRange.Never;
    }, [staticModeActive]);

    const updateChartSurface = async () => {
        if (timeRange.max === 0 && timeRange.min === 0) {
            return;
        }

        if (timeAxis == null || sciChartSurface == null || wasmContext == null) {
            return;
        }

        timeAxis.visibleRange = new NumberRange(timeRange.min, timeRange.max);
        const gap = (timeRange.max - timeRange.min) * 3;
        const dateFrom = Math.round(timeRange.min - gap - userConfig.dataLoadVolume);
        const dateTo = Math.round(timeRange.max + gap);

        const { candleDataSeries } = await setChartSurface(sciChartSurface, wasmContext, dateFrom, dateTo, exchange, symbol, interval);
        setCandlestickDataSeries(candleDataSeries);

        if (staticModeActive || priceAxis == null) {
            return;
        }

        priceAxis.autoRange = EAutoRange.Never;
    }

    const onMouseMove = (event: MouseEvent): void => {
        if (
            !events.current.priceScaleActive &&
            !events.current.timeScaleActive &&
            !events.current.scrollingActive
        ) {
            return;
        }

        const x = (event.clientX - dragPosition.current.x) / 1;
        const y = (event.clientY - dragPosition.current.y) / 1;
        dragPosition.current = { x: event.clientX, y: event.clientY };

        if (events.current.scrollingActive) {
            setDragDelta({ x, y });
        } else if (events.current.timeScaleActive) {
            setZoomDeltaX(x);
        } else if (events.current.priceScaleActive) {
            setZoomDeltaY(y);
        }
    };

    const onPriceMouseDown = (event: MouseEvent): void => {
        events.current.priceScaleActive = true;
        dragPosition.current = { x: event.clientX, y: event.clientY };
    };

    const onTimeMouseDown = (event: MouseEvent): void => {
        events.current.timeScaleActive = true;
        dragPosition.current = { x: event.clientX, y: event.clientY };
    };


    const onWrapperMouseDown = (event: MouseEvent): void => {
        events.current.scrollingActive = true;
        dragPosition.current = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (): void => {
        events.current.priceScaleActive = false;
        events.current.timeScaleActive = false;
        events.current.scrollingActive = false;
    };

    const onMouseLeave = (): void => {
        events.current.priceScaleActive = false;
        events.current.timeScaleActive = false;
        events.current.scrollingActive = false;
    };

    const onMouseWheel = (event: WheelEvent): void => {
        setZoomDeltaX(event.deltaY);
    };

    const onPriceScaleMouseWheel = (event: WheelEvent): void => {
        event.stopPropagation();
        setZoomDeltaY(event.deltaY);
    };

    useEffect(() => {
        if (chartRef.current == null ||
            timeRef.current == null ||
            priceRef.current == null ||
            wrapperRef.current == null
        ) {
            return;
        }

        priceRef.current.addEventListener('mousedown', onPriceMouseDown);
        priceRef.current.addEventListener('wheel', onPriceScaleMouseWheel);
        timeRef.current.addEventListener('mousedown', onTimeMouseDown);
        wrapperRef.current.addEventListener('mousedown', onWrapperMouseDown);
        chartRef.current.addEventListener('mousemove', onMouseMove);
        chartRef.current.addEventListener('mouseup', onMouseUp);
        chartRef.current.addEventListener('wheel', onMouseWheel);
        chartRef.current.addEventListener('mouseleave', onMouseLeave);

        return (): void => {
            if (chartRef.current == null ||
                timeRef.current == null ||
                priceRef.current == null ||
                wrapperRef.current == null
            ) {
                return;
            }

            window.addEventListener('mousemove', onMouseMove);
            priceRef.current.removeEventListener('mousedown', onPriceMouseDown);
            priceRef.current.removeEventListener('wheel', onPriceScaleMouseWheel);
            timeRef.current.removeEventListener('mousedown', onTimeMouseDown);
            wrapperRef.current.removeEventListener('mousedown', onWrapperMouseDown);
            chartRef.current.removeEventListener('mousemove', onMouseMove);
            chartRef.current.removeEventListener('mouseup', onMouseUp);
            chartRef.current.removeEventListener('wheel', onMouseWheel);
            chartRef.current.removeEventListener('mouseleave', onMouseLeave);
        };
    }, []);

    const goToCurrentCandle = (): void => {
        const gap = timeRange.max - timeRange.min;
        const dateByGap = getDateByGap(chartRef.current?.offsetWidth || 0, timeRange.min, timeRange.max);

        const max = moment().utc().unix() + dateByGap;
        const min = max - gap;

        setTimeRange({ min, max });
        setCurrentChartEvent(ChartUserEvent.BackToCurrent);

        if (priceAxis == null) {
            return;
        }

        priceAxis.autoRange = EAutoRange.Always;
    }

    return (
        <div className="sci-canvas-container" ref={chartRef}>
            <div className={"static-mode-togler " + (staticModeActive ? 'active' : '')} onClick={() => setStaticModeActive(active => !active)}>
                <img src={StaticModeIcon} alt="staticMode" />
            </div>
            <PriceScale
                elementRef={priceRef}
                minPrice={priceRange.min}
                maxPrice={priceRange.max}
                currentValue={currentCandle?.close}
                priceTicks={priceTicks}
                backToCurrent={goToCurrentCandle} />
            <TimeScale elementRef={timeRef}
                startDate={timeRange.min}
                endDate={timeRange.max}
                tickCount={userConfig.timeTickCount}
                dateFormat={DATE_FORMAT} />

            <div className="wrapper" ref={wrapperRef}>
                <div style={{ background: appTheme.DarkIndigo, height: '100%' }}>
                    <SciChartReact
                        key="BTC"
                        initChart={initFunc}
                        onInit={(initResult: TResolvedReturnType<typeof initFunc>) => {
                            const { sciChartSurface, ohlcSeries, candlestickSeries, wasmContext, candleDataSeries } = initResult;
                            setCandlestickDataSeries(candleDataSeries);
                            setCandlestickChartSeries(candlestickSeries);
                            setOhlcChartSeries(ohlcSeries);
                            setSciChartSurface(sciChartSurface);
                            setWasmContext(wasmContext);
                        }}
                        style={{ display: "flex", flexDirection: "column", height: "calc(100% - 20px)", width: "100%" }}
                        innerContainerProps={{ style: { flexBasis: "calc(100% - 20px)", flexGrow: 1, flexShrink: 1 } }}
                    >
                        {/* <SciChartNestedOverview
                            style={{ flexBasis: "10%", flexGrow: 1, flexShrink: 1 }}
                            options={overviewOptions}
                        /> */}
                    </SciChartReact>
                </div>
            </div>
        </div>
    );
}

export default CandlestickChart;