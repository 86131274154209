// SCICHART EXAMPLE
import {
    CursorModifier,
    CursorTooltipSvgAnnotation,
    DateTimeNumericAxis,
    EActionType,
    EAutoRange,
    EDataSeriesType,
    EFillPaletteMode,
    ENumericFormat,
    ESeriesType,
    FastCandlestickRenderableSeries,
    FastColumnRenderableSeries,
    FastLineRenderableSeries,
    FastMountainRenderableSeries,
    FastOhlcRenderableSeries,
    GradientParams,
    IFillPaletteProvider,
    IPointMetadata,
    IRenderableSeries,
    MouseWheelZoomModifier,
    NumberRange,
    NumericAxis,
    OhlcDataSeries,
    OhlcSeriesInfo,
    parseColorToUIntArgb,
    Point,
    SciChartSurface,
    SeriesInfo,
    XyDataSeries,
    XyMovingAverageFilter,
    XAxisDragModifier,
    YAxisDragModifier,
    RubberBandXyZoomModifier,
    ZoomExtentsModifier,
    ZoomPanModifier, GlowEffect, ShadowEffect,
    TSciChart,
    HorizontalLineAnnotation,
    EAnnotationLayer,
    EAxisAlignment,
} from "scichart";
//import { ChartEvent, ChartUserEvent, DATE_FORMAT, ElementId, Point, Range, userConfig } from "./entities";
import { appTheme } from "./theme";
import { simpleBinanceRestClient } from "./ExampleData/binanceRectClient"
import { ExampleDataProvider, TPriceBar } from "./ExampleData/ExampleDataProvider";
import { EAutoColorMode } from "scichart/types/AutoColorMode";
import { ElementId, Exchange, surfaceSeriesId, Symbol, Range as TimeRange, userConfig } from "./entities";
import { DataProvider } from "./ExampleData/dataProvider";
import { ApiCandleInterval } from "../../../services/api";
const Y_AXIS_VOLUME_ID = "Y_AXIS_VOLUME_ID";

interface Surface {
    ohlcSeries: FastOhlcRenderableSeries;
    candlestickSeries: FastCandlestickRenderableSeries;
    candleDataSeries: OhlcDataSeries;
}

export const updateAnnotations = (sciChartSurface: SciChartSurface, priceTicks: number[]) => {
    let index = 0;
    //sciChartSurface.annotations.clear();

    priceTicks.forEach(tick => {
        sciChartSurface.annotations.set(index, new HorizontalLineAnnotation({
            stroke: "#6495ED",
            strokeThickness: 0.1,
            y1: tick,
            annotationLayer: EAnnotationLayer.Background
        }))

        index++;
    });
    //@ts-ignore
    console.log(sciChartSurface.annotations.get(0).y1Property);
}

export const createAnnotations = (sciChartSurface: SciChartSurface) => {
    for (let index = 0; index < userConfig.priceTickCount; index++) {
        sciChartSurface.annotations.add(new HorizontalLineAnnotation({
            stroke: "#6495ED",
            strokeThickness: 0.1,
            y1: 0,
            annotationLayer: EAnnotationLayer.Background
        }))
    }
}

export const setChartSurface = async (sciChartSurface: SciChartSurface, wasmContext: TSciChart, dateFrom: number, dateTo: number, exchange: Exchange, symbol: Symbol, interval: ApiCandleInterval): Promise<Surface> => {
    const xValues: number[] = [];
    const openValues: number[] = [];
    const highValues: number[] = [];
    const lowValues: number[] = [];
    const closeValues: number[] = [];
    const volumeValues: number[] = [];

    let priceBars: TPriceBar[];

    priceBars = (await DataProvider.GetCandles(dateFrom, dateTo, exchange, symbol, interval)).sort((c1, c2) => (c1.date > c2.date) ? 1 : -1);

    priceBars.forEach((priceBar: any) => {
        xValues.push(priceBar.date);
        openValues.push(priceBar.open);
        highValues.push(priceBar.high);
        lowValues.push(priceBar.low);
        closeValues.push(priceBar.close);
        volumeValues.push(priceBar.volume);
    });

    // Create and add the Candlestick series
    // The Candlestick Series requires a special dataseries type called OhlcDataSeries with o,h,l,c and date values
    const candleDataSeries = new OhlcDataSeries(wasmContext, {
        xValues,
        openValues,
        highValues,
        lowValues,
        closeValues,
        dataSeriesName: "BTC/USDT",
    });

    const volumeSeries = new FastColumnRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, { xValues, yValues: volumeValues, dataSeriesName: "Volume" }),
        strokeThickness: 1,
        cornerRadius: 1,
        effect: new GlowEffect(wasmContext, {
            range: 1,
            intensity: 0.5,
        }),
        // This is how we get volume to scale - on a hidden YAxis
        yAxisId: Y_AXIS_VOLUME_ID,
        // This is how we colour volume bars red or green
        paletteProvider: new VolumePaletteProvider(
            candleDataSeries,
            appTheme.VividGreen,
            appTheme.MutedRed
        ),
    });

    sciChartSurface.renderableSeries.set(surfaceSeriesId.volume, volumeSeries);

    const candlestickSeries = new FastCandlestickRenderableSeries(wasmContext, {
        dataSeries: candleDataSeries,
        stroke: appTheme.ForegroundColor, // used by cursorModifier below
        dataPointWidth: 0.8,
        brushUp: '#3dab1dFF',
        brushDown: '#fb0a00FF',

        strokeThickness: 0,
        strokeUp: '#3dab1dFF',
        strokeDown: '#fb0a00FF',
        effect: new GlowEffect(wasmContext, {
            range: 1,
            intensity: 0.5,
        }),
        // effect: new ShadowEffect(wasmContext, {
        //     range: 1,
        //     brightness: 100,
        //     offset: new Point(10, 10)
        // }),

        isDigitalLine: false,
    });

    // Add an Ohlcseries. this will be invisible to begin with
    const ohlcSeries = new FastOhlcRenderableSeries(wasmContext, {
        dataSeries: candleDataSeries,
        opacity: 0.2,
        stroke: '#5a678acc', // used by cursorModifier below
        strokeThickness: 2,
        dataPointWidth: 0, // Disable OHLC horizontal lines
        strokeUp: '#5a678acc',
        strokeDown: '#5a678acc',
        isVisible: true,
    });
    sciChartSurface.renderableSeries.set(surfaceSeriesId.ohlc, ohlcSeries);
    sciChartSurface.renderableSeries.set(surfaceSeriesId.candleStick, candlestickSeries);

    // Add some moving averages using SciChart's filters/transforms API
    // when candleDataSeries updates, XyMovingAverageFilter automatically recomputes
    sciChartSurface.renderableSeries.set(surfaceSeriesId.avarage20,
        new FastLineRenderableSeries(wasmContext, {
            dataSeries: new XyMovingAverageFilter(candleDataSeries, {
                dataSeriesName: "Moving Average (20)",
                length: 20,
            }),
            stroke: appTheme.PaleBlue,
        })
    );

    sciChartSurface.renderableSeries.set(surfaceSeriesId.avarage50,
        new FastLineRenderableSeries(wasmContext, {
            dataSeries: new XyMovingAverageFilter(candleDataSeries, {
                dataSeriesName: "Moving Average (50)",
                length: 50,
            }),
            stroke: appTheme.VividPink,
        })
    );

    return { ohlcSeries, candlestickSeries, candleDataSeries }
}

export const initChart = (dateFrom: number, dateTo: number, visibleRange: TimeRange, exchange: Exchange, symbol: Symbol, interval: ApiCandleInterval) => async (rootElement: string | HTMLDivElement) => {
    const theme = appTheme.SciChartJsTheme;
    theme.sciChartBackground = "#001c3677";//appTheme.DarkIndigo;
    theme.loadingAnimationForeground = "#001c3677"; // Red
    theme.loadingAnimationBackground = "#001c36ff"; // Green
    // Create a SciChartSurface
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement, {
        theme: theme,
        autoColorMode: EAutoColorMode.Never,
        drawSeriesBehindAxis: true,
    });

    //createAnnotations(sciChartSurface);
    //sciChartSurface.background = 'rgb(17, 177, 38)';
    // Add an XAxis of type DateTimeAxis
    // Note for crypto data this is fine, but for stocks/forex you will need to use CategoryAxis which collapses gaps at weekends
    // In future we have a hybrid IndexDateAxis which 'magically' solves problems of different # of points in stock market datasetd with gaps
    const xAxis = new DateTimeNumericAxis(wasmContext, {
        isVisible: true,
        drawLabels: false,
        drawMinorGridLines: false,
        autoRange: EAutoRange.Once,
        backgroundColor: '#1d304377',
    });
    sciChartSurface.xAxes.add(xAxis);

    xAxis.visibleRange = new NumberRange(visibleRange.min, visibleRange.max);

    const yAxis = {
        isVisible: true,
        drawLabels: false,
        drawMinorGridLines: false,
        //growBy: new NumberRange(0.1, 0.1),
        labelFormat: ENumericFormat.Decimal,
        labelPrecision: 2,
        labelPrefix: "$",
        autoRange: EAutoRange.Always,
        backgroundColor: '#1d304377',
    }

    sciChartSurface.yAxes.add(
        new NumericAxis(wasmContext,yAxis)
    );

    // Create a secondary YAxis to host volume data on its own scale
    sciChartSurface.yAxes.add(
        new NumericAxis(wasmContext, {
            id: Y_AXIS_VOLUME_ID,
            growBy: new NumberRange(0, 4),
            isVisible: false,
            drawLabels: false,
            autoRange: EAutoRange.Always,
            backgroundColor: '#1d304377',
            // Format to display numbers to display as 1k, 2m, 3B etc
        })
    );

    const { ohlcSeries, candlestickSeries, candleDataSeries } = await setChartSurface(sciChartSurface, wasmContext, dateFrom, dateTo, exchange, symbol, interval);

    // Optional: Add some interactivity modifiers
    sciChartSurface.chartModifiers.add(
        // Enables to select series on click and highlights selected series
        // WARN: disable ZoomPanModifier if you want to use this modifier
        // new RubberBandXyZoomModifier({
        //     // zoomExtentsY: false,
        // }),

        // Enable drag to pan behaviour on axes
        new YAxisDragModifier(),
        new XAxisDragModifier(

        ),

        // Enable zooming via pinch to zoom, mouse wheel zoom etc.
        new ZoomExtentsModifier({
            applyToAxes: true,
            isAnimated: true,
        }),

        // Enable zooming via pinch to zoom, mouse wheel zoom etc.
        new ZoomPanModifier({
            enableZoom: true,
        }),
        // new MouseWheelZoomModifier({
        //     applyToAxes: true,

        // }),
        new CursorModifier({
            crosshairStroke: "cornflowerblue",
            //axisLabelFill: appTheme.VividOrange,
            tooltipLegendTemplate: getTooltipLegendTemplate,
            crosshairStrokeDashArray: [5, 5]
        })
    );

    // Add Overview chart. This will automatically bind to the parent surface
    // displaying its series. Zooming the chart will zoom the overview and vice versa

    //Exporting at the bottom by an object-
    // const overview = await SciChartOverview.create(sciChartSurface, divOverviewId, {
    //     theme: appTheme.SciChartJsTheme,
    //     transformRenderableSeries: getOverviewSeries,
    // });

    return { sciChartSurface, ohlcSeries, candlestickSeries, wasmContext, candleDataSeries };
};

class VolumePaletteProvider implements IFillPaletteProvider {
    fillPaletteMode: EFillPaletteMode = EFillPaletteMode.SOLID;
    private ohlcDataSeries: OhlcDataSeries;
    private upColorArgb: number;
    private downColorArgb: number;

    constructor(masterData: OhlcDataSeries, upColor: string, downColor: string) {
        this.upColorArgb = parseColorToUIntArgb(upColor);
        this.downColorArgb = parseColorToUIntArgb(downColor);
        this.ohlcDataSeries = masterData;
    }

    onAttached(parentSeries: IRenderableSeries): void {
    }

    onDetached(): void {
    }

    // Return up or down color for the volume bars depending on Ohlc data
    overrideFillArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        const isUpCandle =
            this.ohlcDataSeries.getNativeOpenValues().get(index) >=
            this.ohlcDataSeries.getNativeCloseValues().get(index);
        return isUpCandle ? this.upColorArgb : this.downColorArgb;
    }

    // Override stroke as well, even though strokethickness is 0, because stroke is used if column thickness goes to 0.
    overrideStrokeArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        return this.overrideFillArgb(xValue, yValue, index, opacity, metadata);
    }
}

// Override the standard tooltip displayed by CursorModifier
const getTooltipLegendTemplate = (seriesInfos: SeriesInfo[], svgAnnotation: CursorTooltipSvgAnnotation) => {
    let outputSvgString = "";

    // Foreach series there will be a seriesInfo supplied by SciChart. This contains info about the series under the house
    seriesInfos.forEach((seriesInfo, index) => {
        const y = 10 + index * 10;
        const textColor = seriesInfo.stroke;
        let legendText = seriesInfo.formattedYValue;
        if (seriesInfo.dataSeriesType === EDataSeriesType.Ohlc) {
            const o = seriesInfo as OhlcSeriesInfo;
            legendText = `Open=${o.formattedOpenValue} High=${o.formattedHighValue} Low=${o.formattedLowValue} Close=${o.formattedCloseValue}`;
        }
        outputSvgString += `<text x="6" y="${y}" font-size="8" font-family="'Open Sans', sans-serif" fill="${textColor}">
            ${seriesInfo.seriesName}: ${legendText}
        </text>`;
    });

    return `<svg width="100%" height="100%">
                ${outputSvgString}
            </svg>`;
};

// Override the Renderableseries to display on the scichart overview
const getOverviewSeries = (defaultSeries: IRenderableSeries) => {
    if (defaultSeries.type === ESeriesType.CandlestickSeries) {
        // Swap the default candlestick series on the overview chart for a mountain series. Same data
        return new FastMountainRenderableSeries(defaultSeries.parentSurface.webAssemblyContext2D, {
            dataSeries: defaultSeries.dataSeries,
            fillLinearGradient: new GradientParams(new Point(0, 0), new Point(0, 1), [
                { color: appTheme.VividSkyBlue + "77", offset: 0 },
                { color: "Transparent", offset: 1 },
            ]),
            stroke: appTheme.VividSkyBlue,
        });
    }
    // hide all other series
    return undefined;
};

export const overviewOptions = {
    theme: appTheme.SciChartJsTheme,
    transformRenderableSeries: getOverviewSeries,
};