import { userConfig } from "./entities";

export const getDateByGap = (chartWidth: number, dateFrom: number, dateTo: number) => {
    const gap = 100;
    const datePerGap =  (dateTo - dateFrom) / (chartWidth / gap);
    
    return datePerGap;
}

export const getRoundStep = (minPrice: number, maxPrice: number) => {
    const range = maxPrice - minPrice;

    if (range >= 1e9) return 1e8;
    if (range >= 1e6) return 1e4;
    if (range >= 1e3) return 1e2;
    if (range >= 1e2) return 10;
    if (range >= 1e1) return 1;
    return 1;
};

export const roundValue = (value: number, step: number) => {
    return Math.round(value / step) * step;
};

export const generatePriceTicks = (minPrice: number, maxPrice: number): number[] => {
    const stepValue = (maxPrice - minPrice) / (userConfig.priceTickCount - 1); 

    const ticks: number[] = [];

    for (let i = 0; i < userConfig.priceTickCount; i++) {
        const value = minPrice + stepValue * i;

        const roundedValue = roundValue(value, getRoundStep(minPrice, maxPrice));

        ticks.push(roundedValue);
    }

    return ticks;
};