import React from 'react';
import {Outlet} from 'react-router-dom';
import {Box, Container} from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import SideMenu from '../Dashboard/components/SideMenu';
import AppNavbar from '../Dashboard/components/AppNavbar';
import Header from '../Dashboard/components/Header';

export const Layout: React.FC = () => {
    return (
        <Container>
            <CssBaseline enableColorScheme/>

            <Box sx={{
                display: 'flex',
                width: "100%",
                height: "100vh",
                direction: 'column',
            }}>
                <SideMenu/>
                <Box
                    component="main"
                    sx={(theme) => ({
                        flexGrow: 1,
                        overflow: 'auto',
                        width: "100%",

                        height: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                    })}
                >
                    <AppNavbar/>

                    <Stack
                        sx={{
                            alignItems: 'center',
                            mx: 0,
                            pb: 0,
                            mt: {xs: 0, md: 0},
                            flexGrow: 1,
                        }}
                    >
                        <Header/>
                        <Outlet/>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
};

export default Layout;